/*
// .shop-layout
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.shop-layout {
    display: flex;
    justify-content: center;
    position: relative;
}

.sort_by__container {
    height: 40px;
    width: 220px;
    margin-bottom: 24px;

    @media(max-width: 991px) {
        width: 100%;
        margin-top: 12px;
    }
}

.sort_by__block {
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    cursor: pointer;
    padding: 12px 9px;
    margin-bottom: 8px;
    border-radius: 2px;
    align-items: center;
    flex-direction: row;
    border: 1px solid #D2D2D2;
    justify-content: space-between;
}

.sort_by__dropdown_content {
    z-index: 99;
    width: 220px;
    height: auto;
    position: absolute;
    border-radius: 2px;
    border: 1px solid #D2D2D2;
    background-color: #fff;

    @media(max-width: 991px) {
        width: 100%;
    }
}

.dropdown__container {
    display: flex;
    flex-direction: column;

    div {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        color: #4C4C4C;
        cursor: pointer;
        padding: 13px 9px 12px 9px;
        background-color: #fff;
        border-bottom: 1px solid #e8e8e8;
    }

    div:hover {
        background-color: #F5F5F5;
    }

    div.selected {
        background-color: #F5F5F5;
    }
}



@media (min-width: breakpoint(lg-start)) {
    .shop-layout__sidebar {
        width: 297px;
        flex-shrink: 0;
    }
    .shop-layout__content {
        width: calc(100% - 285px);
    }
    .shop-layout--sidebar--start {
        .shop-layout__sidebar {
            @include direction {
                #{$margin-inline-end}: 30px;
            }
        }
    }
    .shop-layout--sidebar--end {
        .shop-layout__sidebar {
            @include direction {
                #{$margin-inline-start}: 30px;
            }
        }
    }
}

@media (max-width: breakpoint(md-end)) {
    .shop-layout {
        flex-wrap: wrap;
    }
    .shop-layout__content,
    .shop-layout__sidebar {
        width: 100%;
    }
    // .shop-layout__sidebar {
    //     order: 1;
    // }
    .shop-layout--sidebar--start,
    .shop-layout--sidebar--end {
        .shop-layout__sidebar {

            @include direction {
                #{$margin-inline-start}: 0;
                #{$margin-inline-end}: 0;
            }
        }
    }
}
