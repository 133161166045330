@import '../../scss/variables';
@import '../../scss/mixins/direction';

.product_inner_page {
    .product_content {
        margin: 80px auto 94px;
        width: 100%;
        max-width: 1045px;
        display: flex;
        justify-content: center;
        gap: 25px;

        .product_galary,
        .product_info {
            width: 50%;

            .blocks__line {
                height: 1px;
                margin: 16px 0;
                background: #e8e8e8 !important;
            }

            .wish__stock_container {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

                .block_1__box {
                    .product_price {
                        color: black;
                    }
                }


                .block_1__box, .block_2__box {
                    gap: 12px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    //justify-content: space-between;

                    .block__name {
                        width: 100%;
                        max-width: 68px;
                        font-size: 14px;
                        white-space: nowrap;
                    }

                    .qty_fm {
                        gap: 5px;
                        margin: 0;
                        height: 32px;
                        max-width: 144px;

                        .qount_fm {
                            height: 32px;
                            max-width: 70px;
                            border: 1px solid #e8e8e8;
                        }

                        .plus_min_fm {
                            width: 32px;
                            height: 32px;
                            color: #43B02A;
                            background: none;
                            border-radius: 50%;
                            border: 1px solid #43B02A;
                        }
                    }

                    .product_price {
                        margin: 0 auto !important;
                        font-size: 20px;
                        //margin: 0 !important;
                    }

                    .product_box_price {
                        margin: 0 auto !important;
                    }

                    .inner_actions_wrapper {
                        display: block;
                        max-width: 170px;

                        .add_card_fm {
                            gap: 2px;
                            display: flex;
                            align-items: center;
                            flex-direction: column;

                            button {
                                gap: 8px;
                                height: 40px;
                                display: flex;
                                border-radius: 2px;
                                background: #43B02A;
                                align-items: center;
                                justify-content: center;
                                font-weight: 400 !important;
                                min-height: unset !important;
                                line-height: 18px !important;

                                svg > path {
                                    stroke: #ffffff;
                                }
                            }

                            .buy__more {
                                color: #9A9A9A;
                                font-size: 12px;
                                text-align: center;
                            }
                        }
                    }

                    @media (max-width: 1024px) {
                        flex-wrap: wrap;

                        .product_price, .product_box_price {
                            margin: 0 !important;
                        }
                    }

                    @media (max-width: 768px) and (min-width: 600px) {
                        flex-wrap: nowrap;

                        .product_price, .product_box_price {
                            margin: 0 auto !important;
                        }
                    }
                }

                .block_2__box {
                    .product_box_price {
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        .not_box_price {
                            color: #AAAAAA;
                            font-size: 14px;
                            line-height: 18px;
                            white-space: nowrap;
                        }

                        .box__price {
                            color: #FF0000;
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 20px;
                            white-space: nowrap;
                        }
                    }

                    .inner_actions_wrapper {
                        align-self: flex-start;
                    }
                }
            }

            .product_galary {
                position: relative;

                .product-gallery__zoom {
                    position: absolute;
                    cursor: pointer;
                    padding: 11px;
                    border: none;
                    z-index: 2;
                    top: 0;
                    background: $product-gallery-zoom-button-normal-bg-color;
                    fill: $product-gallery-zoom-button-normal-icon-color;
                    transition:
                        background .2s,
                        fill .2s;

                    @include direction {
                        #{$inset-inline-end}: 0;
                        #{$border-start-end-radius}: $product-gallery-item-border-radius + .5px;
                        #{$border-end-start-radius}: 2px;
                        transform: scaleX($transform-direction);
                    }

                    &:focus {
                        outline: none;
                    }
                    &:hover {
                        fill: $product-gallery-zoom-button-hover-icon-color;
                    }
                    &:active {
                        fill: $product-gallery-zoom-button-active-icon-color;
                    }
                }

                .slick-slider:not(.thumbnails){
                    border: 1px solid #43b02a;
                    border-radius: 7px;
                }

                .slick-list {
                    .slick-slide {
                        & > div {
                            display: flex;
                        }
                    }
                }

                .thumbnails{
                    width: calc(100% - 50px);
                    margin: 20px auto 0;

                    .slick-slide{
                        padding: 1px;
                    }

                    .slick-current{
                        .image_wrapper{
                            border: 1px solid #43b02a;
                            border-radius: 7px;
                        }
                    }
                }

                .image_wrapper {
                    width: 100%;
                    padding: 14px;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 1;
                        object-fit: contain;
                    }
                }

                .slick-thumb {
                    position: unset;

                    li {
                        width: unset;
                        height: unset;
                        position: unset;
                    }

                    .slick-active {
                        .image_wrapper {
                            border: 0.5px solid #43b02a;
                        }
                    }

                    .image_wrapper {
                        width: 120px;
                        padding: 18px;
                        border: 0.5px solid #AAA;
                        border-radius: 5px;

                        img {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: 1;
                            object-fit: contain;
                        }
                    }
                }
            }

            .product_info {
                .product_name {
                    display: flex;
                    color: #121212;
                    font-size: 32px;
                    font-weight: 600;
                    text-align: left;
                    flex-direction: row;
                    justify-content: space-between;
                }

                .product_description {
                    margin: 8px 0 24px;
                }

                .product_details{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    .product_detail{
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        .detail_name{
                            color: #121212;
                            font-size: 16px;
                            font-weight: 600;
                            text-transform: capitalize;
                        }

                        .detail_value{
                            color: #303030;
                            font-size: 16px;
                            font-weight: 300;
                            text-transform: capitalize;
                        }
                    }

                    .see_more_btn{
                        max-width: fit-content;
                        border: none;
                        background: none;
                        display: flex;
                        align-items: center;
                        padding: 0;
                        cursor: pointer;
                        color: #707070;
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: capitalize;
                        margin-top: -8px;

                        &:hover{
                            text-decoration: underline;
                        }

                        .close{
                            transform: rotate(180deg);
                        }
                    }
                }

                .product_attributes {
                    margin: 28px 0;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    .attr_fms {
                        .attribute_name {
                            color: #000;
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 8px;

                            p {
                                margin: 0;
                            }
                        }

                        .other-list-main-fms {
                            display: flex;
                            gap: 23px;
                            flex-wrap: wrap;

                            .other-list__item {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                padding: 0;
                                margin: 0;

                                .other-list__input {
                                    padding: 0;

                                    .other-radio__body {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 28px;
                                        height: 28px;

                                        .other-radio__input {
                                            width: 20px;
                                            height: 20px;
                                        }
                                    }
                                }

                                .other-list__title {
                                    color: #303030;
                                    font-size: 16px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }

                .product_price,
                .product_price_fm_for_1,
                .product_price_fm_for_pack {
                    color: #000000;
                    font-size: 24px;
                    font-weight: 600;
                    white-space: nowrap;

                    & > span:first-child {
                        color: #121212;
                    }
                }

                .block_1__box {
                    .product_price {
                        align-items: center;
                    }
                }

                .product_price {
                    display: flex;
                    margin: 28px 0;
                    flex-direction: column;

                    .dis_price_fm {
                        font-size: 14px;
                        font-weight: 400;
                        margin-right: 5px;
                        position: relative;
                        margin-bottom: -5px;
                        color: #AAAAAA !important;
                        text-decoration: line-through;

                        div {
                            position: absolute;
                            right: 0;
                            left: 0;
                            height: 1px;
                            background: red;
                            top: 48%;
                            transform: translateY(-50%);
                        }
                    }
                }

                .product_stock {
                    margin: 24px 0;
                }

                .product_qty {
                    color: #707070;
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }

                .qty_fm {
                    margin: 10px 0;
                    gap: 14px;

                    .qount_fm {
                        height: 48px;
                        margin: 0;
                        border-radius: 4px;
                        border: 1px solid #AAA;
                        overflow: hidden;
                    }

                    .plus_min_fm {
                        width: 48px;
                        height: 48px;
                        border-radius: 6px;
                    }
                }

                .inner_actions_wrapper {
                    gap: 13px;

                    .add_card_fm {
                        max-width: 242px;

                        button {
                            min-height: 48px;
                            color: #FFF;
                            text-align: center;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }

                    .wishList_button_fm {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }

                .product_share {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-top: 28px;

                    span {
                        color: #707070;
                        font-size: 18px;
                        font-weight: 400;
                    }

                    .share_icons {
                        display: flex;
                        align-items: center;
                        gap: 24px;
                    }
                }
            }
        }

        .related_items_fm {
            margin: 100px 0;

            .title_fm {
                color: #000;
                font-size: 32px;
                font-weight: 600;
                margin-bottom: 32px;
            }

            .slick-list{
                width: calc(100% - 20px);
                margin: 0 auto;
            }

            .category_list_fm {
                .cat_items_fm {
                    .block-brands__item {
                        //padding-right: 24px;
                        padding: 0 12px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .product_inner_page {
            .product_content {
                margin: 32px auto 44px;
                width: 100%;
                flex-direction: column;
                gap: 24px;

                .product_galary,
                .product_info {
                    width: 100%;
                }

                //.product_galary {
                //    .slick-list {
                //        border: 1px solid #43b02a;
                //        border-radius: 7px;
                //        margin-bottom: 20px;
                //
                //        .slick-slide {
                //            & > div {
                //                display: flex;
                //            }
                //        }
                //    }
                //
                //    .image_wrapper {
                //        width: 100%;
                //        padding: 14px;
                //
                //        img {
                //            width: 100%;
                //            height: 100%;
                //            aspect-ratio: 1;
                //            object-fit: contain;
                //        }
                //    }
                //
                //    .slick-thumb {
                //        position: unset;
                //
                //        li {
                //            width: unset;
                //            height: unset;
                //            position: unset;
                //        }
                //
                //        .slick-active {
                //            .image_wrapper {
                //                border: 0.5px solid #43b02a;
                //            }
                //        }
                //
                //        .image_wrapper {
                //            width: 120px;
                //            padding: 18px;
                //            border: 0.5px solid #AAA;
                //            border-radius: 5px;
                //
                //            img {
                //                width: 100%;
                //                height: 100%;
                //                aspect-ratio: 1;
                //                object-fit: contain;
                //            }
                //        }
                //    }
                //}

                .product_info {
                    .product_name {
                        font-size: 20px;
                    }

                    .product_description {
                        margin: 4px 0 16px;
                    }

                    .product_attributes {
                        margin: 16px 0;
                    }

                    .product_price {
                        margin: 16px 0;
                    }

                    .product_stock {
                        margin: 16px 0;
                    }

                    .product_share {
                        margin-top: 12px;
                    }
                }
            }

            .related_items_fm {
                margin: 44px 0;

                .title_fm {
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                .category_list_fm {
                    .cat_items_fm {
                        .block-brands__item {
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 425px) {
        .product_inner_page {
            .product_content {
                .product_info {
                    .qty_fm {
                        justify-content: space-between;

                        .qount_fm {
                            max-width: unset;
                        }
                    }

                    .inner_actions_wrapper{
                        .add_card_fm{
                            max-width: unset;
                        }
                    }
                }
            }

            .related_items_fm {
                margin: 44px 0;

                .title_fm {
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                .category_list_fm {
                    .cat_items_fm {
                        .block-brands__item {
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }
