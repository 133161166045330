.categoroy-children-wrapper {
    width: 100%;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 42px;
    @media (max-width: 766px) {
        justify-content: space-between;
        padding: 0 15px 15px 15px;
    }
}

.container_m {

    @media (max-width: 766px) {
        width: 100%;
    }
}

.main-routes {

    border-bottom: 1px solid grey;

    ul {
        margin: unset;
        padding: unset;
        display: flex;
        // position: relative;
        align-items: center;
        flex-wrap: wrap;
        // grid-template-columns: repeat(4, 1fr);
        gap: 45px;

        @media (max-width: 991px) {
            display: none;
        }

        a {
            font-size: 32px;
            font-weight: 500;
            color: #808080;

            &.selected {
                color: #121212;
                border-bottom: 3px solid #379237;
            }

            &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
                text-decoration: none;
                pointer-events: none;
            }

            li {
                list-style: none;
            }
        }
    }

    @media (max-width: 991px) {
        border-bottom: unset
    }
}

.mobile-router {
    display: none;

    > div {

        display: flex;
        align-items: center;
        gap: 20px;
        margin: 20px 0;

        h4 {
            margin: unset;
            font-size: 18px;
            color: #121212;
            font-weight: 400;
        }

    }

    @media (max-width: 991px) {
        display: block;
    }
}

.category-children-list {
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, calc((100% - 105px) / 4));
    gap: 32px 35px;

    @media (max-width: 991px) {
        grid-template-columns: repeat(3, calc((100% - 70px) / 3));
    }

    @media (max-width: 785px) {
        grid-template-columns: repeat(2, calc((100% - 10px) / 2));
        gap: 10px;
        margin-top: 24px;
    }

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 24px;
    }

    .selectedCategory-item-card {
        max-width: 356px;
        width: 100%;
        position: relative;
    }


    img {
        width: 100%;
        border-radius: 12px;
        height: 100%;
        aspect-ratio: 1.37;
        object-fit: cover;
        //transform: scale(0.9);
        transition: transform 0.5s ease-out;

        &:hover {
            transform: scale(1);
            transition: transform 0.5s ease-out;
        }
    }
}

.selectedCategory-name {
    //position: absolute;
    //top: 43%;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
    align-items: center;
    margin-top: 12px;

    span {
        color: #121212;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;

        @media (max-width: 425px) {
            font-size: 16px;
            text-overflow: unset;
            white-space: normal;
            overflow: unset;
        }
    }

}

.selectedCategory-title {
    text-align: center;
    display: none;

    @media (max-width: 785px) {
        display: block;

    }

    h2 {
        color: #121212;

    }
}
