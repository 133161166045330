/*
// .breadcrumbs
*/
@import '../variables';
@import '../functions';


.breadcrumbs {
    background: #EDEDED;
    padding: 10px 0;
    color: #121212;
    font-size: 14px;
    font-weight: 400;

    .navigation {
        display: flex;
        gap: 3px;
        max-width: unset;

        .path {
            text-transform: capitalize;
            text-decoration: underline;
            color: #121212;
            cursor: pointer;

            &.last_path {
                color: #898989;
            }
        }
    }

}
