/*
// .site
*/
@import '../variables';


.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    flex-grow: 1;
    // background: #F5F5F5;
    //padding-top: 32px;
}
.site__footer {
    background: #EEEEEE;
    flex-shrink: 0;
}
