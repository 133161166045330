/*
// .topbar-link
*/
@import '../variables';


.topbar-link {
    color: $topbar-link-color;
    transition: color .3s;
}
