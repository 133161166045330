/*
// .widget
*/
@import '../variables';


.widget {}
.widget__title {
    margin-bottom: 0;
    font-size: 20px;
    @media (max-width:420px) {
        font-size: 18px;
    }
}
.open-filters-block{
    padding: 10px 20px !important;
    border: 1px solid #333;
}
.main-filters-block{
    border: 1px solid #c5c5c5;
}

.filter-open-field{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-title-nw{
    gap: 7px;
    display: flex;
    align-items: center;
}

.filter-inputs {
    width: 100%;
    padding: 12px 0 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
        position: relative;
        width: auto;
        height: auto;

        input {
            width: 104px;
            height: 32px;
            padding: 5px 8px;
            border-radius: 2px;
            border: 1px solid #AAAAAA;
            padding-right: 20px;

            @media(max-width: 340px) {
                width: 80px;
            }
        }

        span {
            position: absolute;
            right: 6px; /* Регулируйте это значение в зависимости от желаемого расстояния между символом и правым краем инпута */
            top: 50%;
            transform: translateY(-50%);
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

    }
}
