/*
// .nav-panel
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.nav-panel {
  width: 100%;
  background: transparent;
}

.nav-panel--stuck {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: $nav-panel-stuck-shadow;
}

.nav-panel__container {
  height: 100%;
}

.nav-panel__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 25px;
  height: 100%;
}

.logo-menu-wrapper {
  display: flex;
  gap: 30px;
}

.site_menu_icon {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  span {
    font-size: 20px;
    color: #121212;
  }
}

.nav-panel-langs {
  ul {
    display: flex;
    gap: 40px;
    margin: unset;

    li {
      font-size: 20px;
      list-style: none;
      color: #121212;

      cursor: pointer;

      &:hover {
        text-decoration: underline solid #43B02A;
      }
    }
  }
}

.nav-panel__logo svg {
  display: block;
  fill: $nav-panel-logo-color;
}

.nav-panel__departments {
  flex-shrink: 0;
}

.nav-panel__logo + .nav-panel__nav-links,
.nav-panel__departments + .nav-panel__nav-links {
  @include direction {
    #{$margin-inline-start}: 18px;
  }
}

.nav-panel__nav-links {
  @include direction {
    #{$margin-inline-end}: 18px;
  }
}

.nav-panel__indicators {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  // @include direction {
  //     #{$margin-inline-start}: auto;
  // }
}

.count_cart_fm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #379237;
  font-size: 10px;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  top: 6px;
  right: -6px;
}

.nav_icon_fm {
  position: relative;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 13px 0;
  height: 54px;

  &.signed_user {
    color: #000;
  }

  .account_popup {
    display: none;
    flex-direction: column;
    background: #FFF;
    border: 1px solid #AAA;
    border-radius: 4px;
    position: absolute;
    //top: calc(100% + 13px);
    top: 100%;
    right: 0;
    overflow: hidden;

    .account-nav__item {
      list-style: none;
      padding: 13px 12px;
      border-bottom: 1px solid #AAA;

      &:last-child {
        border: none;
      }

      &:hover {
        background: #F7F7F7;
      }

      a {
        padding: 0;
        margin: 0;
        text-wrap: nowrap;
        color: #303030;
        font-size: 16px;
        font-weight: 400;

        &:hover {
          background: none;
        }
      }
    }

  }

  &:hover {
    .account_popup {
      display: flex;
    }
  }
}

.new_nav_icon {
  svg {
    @media (max-width: 420px) {
      width: 100px;
    }
  }

}

.login_nav_fm {
  margin-left: 6px;
  font-size: 18px;
  color: #121212;
  font-weight: 400;
}

.crat_butt_fm {
  width: 200px;
  min-height: 44px;
  background: #379237;
  border: none;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  border-radius: 6px;
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
  .nav-panel__nav-links {
    @include direction {
      #{$margin-inline-start}: 14px;
      #{$margin-inline-end}: 14px;
    }
  }
}
