/*
// .modal
*/
@import '../variables';


.modal {
    -webkit-overflow-scrolling: touch;
}
.modal-content {
    border: none;
    border-radius: 4px;
    box-shadow: $modal-shadow;
}

.search-mobile-mpdal{
    .modal-content{
        margin-top: 56px !important;
    }
}