/*
// .footer-newsletter
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.footer-newsletter__title {
    margin-bottom: 25px;
}
.footer-newsletter__text {
    font-size: 18px;
    margin-bottom: 16px;
    color: #121212;
    font-weight: 600;
}
.footer-newsletter__text--social {
    margin-top: 20px;
    margin-bottom: 0;
}

.footer-newsletter__social-links {
    display: flex;
    margin-top: 8px;
}

.footer-newsletter__form{
    // max-width: 316px;
    text-align: right;
}


.footer-newsletter__form-input {
    width: 100%;
    height: 40px;
    border: 1px solid #B8B8B8;
    margin-bottom: 16px;
    background: transparent;
    &::placeholder{
        color: #B8B8B8;
        font-size: 18px;
    }
}
.footer-newsletter__form-button {

    width: 100%;
    height: 40px;
    color: #ffffff;
    background: #43B02A;
    border-radius: 2px;
    font-size: 18px;
    border: none;
}



@media (max-width: breakpoint(md-end)) {
    .footer-newsletter {
        //margin-top: 42px;
        max-width: 420px;
    }
    .footer-newsletter__title {
        margin-bottom: 16px;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .footer-newsletter {
        text-align: center;

        @include direction {
            #{$margin-inline-start}: auto;
            #{$margin-inline-end}: auto;
        }
    }
    .footer-newsletter__title {
        font-size: 28px;
    }
    .footer-newsletter__form {
        margin: 0 auto;
    }
    .footer-newsletter__social-links {
        margin-top: 16px;
        justify-content: center;
    }
}
