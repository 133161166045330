/*
// .block-banner
*/
@import '../variables';
@import '../mixins/preloader';


.block-loader {
    
   height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.block-loader__spinner {
    @include preloader(80px);
}
.block-content{

    max-width: 558px;
    padding: 0 20px;
    width: 100%;
    // max-height: 480px;
    // height: 100%;
    img{
        width: 100%;
    }
        
}
