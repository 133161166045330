/*
// .card
*/
@import '../variables';
@import '../functions';

.account_credit_balance{
    display: flex;
    margin-bottom: 16px;
    font-weight: 600;

    @media screen and (max-width: 991px) {
        margin-bottom: 10px;
    }
}

.card {
    border: $card-border;
    border-radius: $card-border-radius;

    .set-history-date-container {
        padding: 10px 30px;
        display: flex;

        & > div {
            display: flex;
            flex-direction: column;
            max-width: calc((100% - 10px) / 2);

            &:last-child {
                margin-left: 10px;
            }
        }

        @media screen and (max-width: 768px){
            padding: 0;
            border-bottom: 1px solid #f5f5f5;
            padding-bottom: 12px;
        }
    }
}
.card-body {
    padding: 2rem;

    .form-group{
        .form-check{
            span,
            label{
                cursor: pointer;
            }
        }
    }
}
.card-header,
.card-footer {
    padding: 1.5rem 2rem;
    border-top: none;
    border-bottom: none;
    background-color: transparent;

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
    }
}

.contact_us_fms{
    background: #fff;
    margin-top: -34px;
    padding-bottom: 34px;
}

.contact_us_fms .card-title,.contact_us_fms  h2 {
    color: #2d2d2d;
    font-weight: normal;
    font-size: 30px;
}

.contact_us_fms .form-control {
    border-color: #D1D1D1;
    color: #2d2d2d;
    font-size: 14px;
    border-radius: 4px;
    ::placeholder{
        color: #D1D1D1;
        font-size: 14px;
    }

}

.contact_us_fms input.form-control {
    height: 40px;
}

.contact_us_fms h3 {
    color: #2d2d2d;
    font-weight: normal;
    font-size: 24px;
}

.card-title {
    margin-bottom: 1.75rem;
    color: #2d2d2d;
    font-weight: normal;
    font-size: 34px;



    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
    }
}

.shipping_methods {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .shipping_method {
        display: flex;
        flex-direction: column;

        .option__container {
            gap: 10px;
            display: flex;
            align-items: center;
            flex-direction: row;

            input {
                cursor: pointer;
            }

            label {
                margin: 0;
                width: 100%;
                cursor: pointer;
            }
        }

        .option_desc {
            color: #707070;
            font-size: 14px;
            padding-left: 23px;
        }
    }
}

.card-divider {
    height: 2px;
    background: $card-border-color;
}
.card-body .card-title:last-child {
    margin-bottom: 0;
}


@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .card-header,
    .card-footer {
        padding: 1.25rem 1.5rem;
    }
    .card-title {
        font-size: 26px;
        margin-bottom: 1.5rem;
    }
    .card-body {
        padding: 1.5rem;
    }
}


@media (max-width: breakpoint(sm-end)) {
    .card-header,
    .card-footer {
        padding: 1rem 1.375rem;
    }
    .card-title {
        font-size: 26px;
        margin-bottom: 1.5rem;
    }
    .card-body {
        padding: 1.375rem;
    }

    .contact_us_fms{
        background: #fff;
        margin-top: -15px;
        padding-top: 30px;
        padding-bottom: 34px;
    }

    .contact_us_fms .card-title {
        font-size: 18px;
    }

    .contact_us_fms p {
        margin-bottom: 17px;
        color: #2D2D2D;
        font-size: 16px;
    }
}
