/*
// .footer-links
*/
@import '../variables';
@import '../functions';


.footer-links {}
.footer-links__title {
    margin-bottom: 22px;
}
.footer-links__list {
    font-size: 14px;
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        margin-bottom: 8px;
    }
    a {
        color: #2D2D2D;
        transition: .15s;
    }
}


@media (max-width: breakpoint(sm-end)) {
    .footer-links__title {
        margin-bottom: 12px;
    }
    .footer-links {
        margin-top: 42px;
        text-align: center;
    }
}