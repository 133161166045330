/*
// .checkout
*/
@import '../variables';
@import '../mixins/direction';

.checkout__totals {
    width: 100%;

    .checkout__totals-header {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid $card-divider-color;

        div {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .checkout__totals-products {
        width: 100%;
        display: flex;
        flex-direction: column;

        .checkout__product {
            gap: 24px;
            width: 100%;
            padding: 16px 0;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            .product-name__img {
                gap: 24px;
                display: flex;
                align-items: center;
                flex-direction: row;

                .product__image {
                    border: 2px solid #f0f0f0;
                    border-radius: 2px;
                    padding: 2px;
                }

                .product__name {
                    //TODO!!! use the code in comments if you need to hide text overflow after 3th line
                    //display: -webkit-box;
                    //-webkit-box-orient: vertical;
                    //overflow: hidden;
                    //text-overflow: ellipsis;
                    //-webkit-line-clamp: 3;
                    font-size: 18px;
                    text-align: left;
                    margin-bottom: unset;

                    @media(max-width: 768px) {
                        font-size: 16px;
                    }
                }
            }

            .product__price {
                text-align: right;
                white-space: nowrap;
            }
        }
    }

    .checkout__totals-subtotals {
        gap: 10px;
        width: 100%;
        display: flex;
        padding: 16px 0;
        flex-direction: column;
        border-top: 1px solid $card-divider-color;
        border-bottom: 1px solid $card-divider-color;

        .checkout__subtotals, .checkout__shipping {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .checkout__totals-footer {
        display: flex;
        padding: 16px 0;
        flex-direction: row;
        justify-content: space-between;
    }
}









.checkout__totals-header {
    tr:last-child > * {
        padding-bottom: 10px;
    }
}
.checkout__totals-products {
    tr:first-child > * {
        padding-top: 20px;
        border-top: 1px solid $card-divider-color;
    }
    tr:last-child > * {
        padding-bottom: 20px;
        border-bottom: 1px solid $card-divider-color;
    }
}
.checkout__totals-subtotals {
    tr:first-child > * {
        padding-top: 20px;
    }
    tr:last-child > * {
        padding-bottom: 20px;
        border-bottom: 1px solid $card-divider-color;
    }
}
.checkout__totals-footer {
    font-size: 20px;

    th {
        font-weight: normal;
    }

    tr:first-child > * {
        padding-top: 20px;
    }
}




.payment-methods {
    margin-bottom: 30px;
    .payment-methods__available{
        color: #F2C94C;
        padding-bottom: 10px;
        display: block;
        padding-top: 10px;
    }
}
.payment-methods__list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.payment-methods__item {
    border-radius: $payment-methods-border-radius;
    background: $payment-methods-default-bg;
    border: $payment-methods-default-border;

    &:hover {
        background: $payment-methods-hover-bg;
        border: $payment-methods-hover-border;
    }

    & + & {
        margin-top: 6px;
    }
    position: relative;
    & > .disabled-style {
        position: absolute;
        z-index: 9;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #cccccc8a;
    }
}
.payment-methods__item-header {
    display: flex;
    // align-items: center;
    padding: 8px 14px;
    font-size: 15px;
    font-weight: $font-weight-medium;
    letter-spacing: .01em;
    cursor: pointer;
    margin-bottom: 0;
}
.payment-methods__item-radio {
    padding-top: 3px;
    @include direction {
        #{$margin-inline-end}: 10px;
    }
}
.payment-methods__item-container {
    overflow: hidden;
    height: 0;
    transition: height .2s;
}
.payment-methods__item-description {
    font-size: 15px;
    line-height: 20px;
    padding: 2px 16px 13px 16px;
}
.payment-methods__item--active {
    &, &:hover {
        background: $payment-methods-active-bg;
        border: $payment-methods-active-border;
    }

    .payment-methods__item-container {
        height: auto;
    }
}

.checkout__agree {
    margin-bottom: 36px;

    a:hover {
        text-decoration: underline;
    }
}

.delivery_information {
    gap: 5px;
    display: flex;
    flex-direction: row;

    svg {
        min-width: 22px;
        min-height: 22px;
    }

    p {
        color: #303030;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 12px;
    }
}
