/*
// .account-nav
*/
@import '../variables';
@import '../functions';
@import '../mixins/card';
@import '../mixins/direction';


.account-nav {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
.account-nav__item a {
    display: block;
    color: #2D2D2D;
    font-size: 18px;
    margin-bottom: 12px;

    &:hover {
        background-color: #f7f7f7;
    }
}


@media (min-width: breakpoint(lg-start)) {
    .account-nav {
        @include card;

        ul {
            padding-bottom: 18px;
        }
    }
    .account-nav__title {
        font-size: 20px;
        padding: 24px 26px 0;
        margin-bottom: 1rem;
    }
    .account-nav__item a {
        padding: 5px 26px;
    }
    .account-nav__item--active a {
        color: #43B02A;
        font-weight: $font-weight-medium;
    }
}


@media (max-width: breakpoint(md-end)) {
    .account-nav__title {
        display: none;
    }
    .account-nav {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        ul {
            display: block;
            flex-wrap: nowrap;
            white-space: nowrap;
            width: 100%;
            border-bottom: $card-border-width solid $card-border-color;
        }
    }
    .account-nav__item a {
        padding: 8px 16px;
        margin-bottom: -2px;
        border-bottom: $card-border-width solid $card-border-color;
    }
    .account-nav__item--active a {
        color: #43B02A;
        font-weight: $font-weight-medium;
    }
}

@media screen and (max-width: 425px) {
    .account-nav__item a {
        padding: 8px 0;
    }
}


