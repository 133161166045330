/*
// .badge
*/
@import '../variables';


.badge {
    border-radius: 1.5px;
    font-weight: $font-weight-medium;
    padding: .25em .5em;
}