/*
// .widget-filters
*/
@import '../variables';
@import '../functions';
@import '../mixins/card';
@import '../mixins/direction';


@mixin local-offcanvas() {
    border: none;
    padding-top: 0;

    .widget-filters__title {
        display: none;
    }
    .widget-filters__item:first-child {
        border-top: none;
    }
    .widget-filters__item {
        border-width: 1px;
    }
}


.widget-filters {
    // @include card();
    border-radius: 2px;
    padding: 10px;
    // @media(max-width:991px){
    //     border: 1px solid #333;
    // }
}
// .widget-filters__item {
    // padding: 8px $widget-shop-padding;
//     margin: 0 (-$widget-shop-padding);
//     border-bottom: 2px solid $card-border-color;

//     &:first-child {
//         border-top: 2px solid $card-border-color;
//     }
// }
.widget-filters__actions {
    padding-top: 24px;
    padding-bottom: 16px;

    & > .btn + .btn {
        @include direction {
            #{$margin-inline-start}: 8px;
        }
    }
}

.reset_btn {
    background-color: #dedede;
}


.widget-filters--offcanvas--always {
    @include local-offcanvas;
}


.widget-filters--offcanvas--mobile {
    border: 2px solid #f0f0f0 !important;
    @media (max-width: breakpoint(md-end)) {
        @include local-offcanvas;
    }
}

.see-all-block{

    font-size: 16px;
    font-weight: 400;
    color: #707070;

}
