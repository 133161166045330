// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
:focus {
    outline: none;
}

.modal-backdrop {
    width: 100% !important;
    height: 100% !important;
}

.disabled {
    opacity: 0.7;

}

button:focus {
    border: none;
    outline: none;
}

.header_nav_fm .user_svg_fm path {
    fill: #ffffff;
}

.header_nav_fm .cart_svg_fm path {
    stroke: #ffffff;
}

.header_nav_fm .login_nav_fm {
    color: #ffffff;
}

.header_nav_fm .topbar__item-value {
    color: #9f9f9f;
}

.header_nav_fm .topbar-dropdown__btn svg {
    fill: #9f9f9f;
}

.site {
    position: relative;
}

.search__body form {
    width: 100%;
    border: 1px solid #e2e2e2;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_fm {
    max-width: 1250px;
    padding: 0 8px;
    margin: 0 auto;

    &.brands_container_in_catalog {
        display: grid;
        grid-template-columns: calc(100%);

        .brands_catalog_page {
            position: relative;
            padding: 0 20px;

            .slick-track {
                margin: 0;
            }

            & > .left-button,
            .right-button {
                position: absolute;
                top: 45%;
                bottom: 0;
                cursor: pointer;
                margin: auto auto;
            }

            & > .left-button {
                right: 0;
            }

            & > .right-button {
                left: 0;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        &.brands_container_in_catalog {
            display: grid !important;
            grid-template-columns: 100% !important;
        }
    }
}

.row_footer_fm {
    display: flex;
    gap: 10px;

    @media (max-width: 425px) {
        flex-direction: column;
        align-items: center;
    }
}

.about_us_fm {
    padding-bottom: 25px;
}

.search__body select {
    margin-right: 8px;
    width: 142px;
    height: 44px;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #636363;
    background: transparent;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
    padding-left: 10px;

    &:focus {
        outline: none;
    }
}

.search__body div {
    position: relative;
    width: 100%;
}

.search__body input {
    width: 100%;
    height: 44px;
    border: none;
    border-left: 1px solid #e2e2e2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:focus {
        outline: none;
    }
}

.nav_menu_fm {
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    padding: 0 8px;
}

.nav_menu_fm div {
    margin: 0 15px;
}

.nav_menu_fm div a {
    color: #636363 !important;
    font-size: 18px;
    font-weight: 400;
}

.slick-slider.home_slider {
    width: 100%;
    height: 100%;
    aspect-ratio: 2.743;
    //max-height: 700px;
    //min-height: 300px;

    //@media screen and (max-width: 425px) {
    //    min-height: 200px;
    //}

    .slick-prev,
    .slick-next {
        height: unset;
        width: unset;
        z-index: 1;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    .slick-dots {
        bottom: 15px;
        display: flex !important;
        justify-content: center;
        gap: 12px;

        li {
            margin: 0;
            height: unset;
            width: unset;


            button {
                width: 12px;
                height: 12px;
                padding: 0;
                margin: 0;
                border-radius: 50%;
                background: #FFF;

                &:before {
                    display: none;
                }
            }
        }

        .slick-active {
            button {
                background: #797979;
            }
        }
    }

    .slick-list {
        height: 100%;
        //@media screen and (max-width: 425px) {
        //    min-height: 200px;
        //}

        .slick-track {
            height: 100%;

            .slick-slide {
                height: 100%;

                & > div{
                    height: 100%;
                }

                .slide {
                    position: relative;
                    height: 100%;
                    color: unset;

                    .slide_content_wrapper {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        position: absolute;
                        left: 200px;
                        top: 0;

                        @media screen and (max-width: 425px) {
                            left: 50px;
                        }
                    }
                }

                img{
                    height: 100%;
                    width: 100%;
                    //object-fit: cover;
                }
            }
        }
    }
}

.home_category_fm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1302px;
    margin: 100px auto 80px;
    padding: 0 21px;

    h1 {
        margin-bottom: 32px;
        color: #000;
        font-size: 40px;
        font-weight: 700;
        text-transform: capitalize;
    }

    .list_fm {
        display: grid;
        grid-template-columns: repeat(3, calc((100% - 48px) / 3));
        gap: 24px;
        justify-content: space-between;
        width: 100%;

        .item_fm {
            background: #ffffff no-repeat;
            background-size: 100%;

            .image_wrapper {
                width: 100%;
                padding: 40px;
                aspect-ratio: 1.2625;
            }

            .new_deppot_category_content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 11px;
                pointer-events: none;

                h3 {
                    color: #121212;
                    font-size: 28px;
                    font-weight: 400;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 8px;

                    @media (max-width: 425px) {
                        font-size: 24px;
                    }
                }

                .category_card_descr {
                    color: #303030;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 14px;
                }

                span {
                    font-size: 20px;
                    color: #303030;
                }

                a {
                    min-width: 190px;
                    height: 40px;
                    background: #379237;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    padding: 10px 25px;
                    gap: 20px;

                    span {
                        color: #ffffff;
                        text-align: center;
                        font-size: 16px;
                    }

                    &:hover {
                        background: #207b20;
                    }
                }
            }

            &:first-child {
                grid-column: 1/4;
                position: relative;

                .image_wrapper {
                    width: 100%;
                    padding: 0;
                    height: 100%;
                    max-height: 400px;
                    aspect-ratio: unset;
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;

                    svg{
                        height: 90%;
                        width: unset;
                        pointer-events: none;
                    }
                }

                .new_deppot_category_content {
                    position: absolute;
                    top: 0;
                    left: 200px;
                    height: 100%;
                    max-width: 330px;
                    margin: 0;
                    align-items: start;

                    @media screen and (max-width: 600px) {
                        left: 130px;
                    }

                    h3 {
                        color: #FFF;
                        font-size: 56px;
                        font-weight: 600;
                        margin-bottom: 11px;
                        overflow: unset;
                        white-space: unset;
                        text-align: start;
                    }

                    .category_card_descr {
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        .list_fm {
            grid-template-columns: repeat(2, calc((100% - 24px) / 2));

            .item_fm {
                &:first-child {
                    grid-column: 1/3;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .list_fm {
            .item_fm {
                &:first-child {
                    //.image_wrapper {
                    //    svg {
                    //        width: 180px;
                    //        height: 180px;
                    //    }
                    //}

                    .new_deppot_category_content {
                        max-width: unset;
                        width: 60%;
                        left: 20%;
                        align-items: center;

                        h3 {
                            font-size: 40px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    //@media screen and (max-width: 425px) {
    @media screen and (max-width: 430px) {
        margin: 56px auto 46px;
        padding: 0 15px;

        h1 {
            margin-bottom: 24px;
            font-size: 28px;
        }

        .list_fm {
            grid-template-columns: 100%;

            .item_fm {
                .image_wrapper {
                    padding: 0 22px;
                    aspect-ratio: 1.323;
                }

                .new_deppot_category_content {
                    margin-top: 3px;

                    h3 {
                        margin-bottom: 6px;
                    }
                }

                &:first-child {
                    grid-column: unset;

                    .image_wrapper {
                        //aspect-ratio: 1.875;
                        height: unset;

                        //svg {
                        //    width: 100px;
                        //    height: 100px;
                        //}
                    }


                    .new_deppot_category_content {
                        width: 100%;
                        position: relative;
                        align-items: center;
                        left: unset;
                        height: unset;
                        max-width: unset;
                        margin-top: 8px;

                        h3 {
                            color: #121212;
                            font-size: 24px;
                            font-weight: 400;
                            text-align: center;
                            margin-bottom: 12px;
                        }

                        .category_card_descr {
                            margin-bottom: 12px;
                            display: none;
                        }
                    }
                }
            }
        }
    }


}

.new_deppot_category_card {
    width: 100%;
    height: 100%;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
        pointer-events: none;
    }

    @media (max-width: 762px) {
        max-width: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media only screen and (max-width: 991px) {
    .login_nav_fm {
        font-size: 16px;
        white-space: nowrap;
    }

    .crat_butt_fm {
        width: 160px;
    }

    .nav_icon_fm {
        margin-left: 1em;
    }
}

.site-footer__widgets .row_footer_fm {
    justify-content: space-between;
}

.site-footer__widgets .row_footer_fm .col_block_fm {
    width: 33%;

    @media (max-width: 425px) {
        width: 100%;
    }
}

.site-footer__widget .logo_fm .shop_fm {
    margin-left: 16px;
}

.site-footer__widget .logo_fm {
    margin-bottom: 33px;
}

.site-footer__widget .info_fm span {
    display: block;
    font-size: 14px;
    color: #2d2d2d;
    margin-bottom: 8px;
}

.social_icon_fm {
    margin-top: 8px;
}

.social_icon_fm img {
    margin-right: 12px;
}

.site-footer__payment_icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    @media (max-width: 991px) {
        flex-wrap: wrap;
        padding: 16px 0;
    }
}

.site-footer__copyright > div:last-child {
    font-size: 14px;
    font-weight: 400;
    color: #2d2d2d;
}

.site-footer__copyright > div:last-child img {
    margin-left: 16px;
}

.dep_fms {
    display: flex !important;
    justify-content: space-between;
}

@media only screen and (max-width: 768px) {
    .home_img_fm {
        margin-top: -95px;
    }

    .topbar__row {
        justify-content: center;
    }

    .nav-panel {
        position: relative;
        height: auto;
    }

    .topbar {
        height: 30px;
    }

    .topbar__item-value {
        font-size: 10px;
        color: #ffffff;
        font-weight: 400;
    }

    .lang_image {
        width: 14px;
        height: 11px;
    }

    .home_img_fm {
        background-position: -366px;
    }

    .topbar-dropdown__btn {
        svg {
            fill: #ffffff;
        }
    }

    .nav-panel__container {
        width: 100%;
        padding: 0 8px;

        &.container_fm {
            display: none;
        }
    }

    .nav_menu_fm div {
        margin: 0;
    }

    .nav-panel__row {
        .nav-panel__indicators {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .nav_icon_fm {
                margin: 0;
                margin-right: 5px;

                .us_fm_logo {
                    margin-right: 17px;
                    margin-left: 17px;
                }

                .count_cart_fm {
                    width: 12px;
                    height: 12px;
                    font-size: 7px;
                    top: -5px;
                }
            }
        }
    }

    .search_logo_fm .logo_fm img {
        width: 144px;
    }

    .search__body input {
        border-radius: 4px;
        max-width: 100%;
    }

    .nav_menu_fm {
        justify-content: space-between;

        div:first-child {
            a {
                color: #61ac40 !important;
            }
        }

        div {
            img {
                margin: 0 auto;
            }

            a {
                font-size: 12px;
            }
        }
    }

    .site-footer {
        padding: 14px 8px 0;

        .site-footer__widgets {
            padding-top: 0;
            margin-top: 0;
            padding-bottom: 8px;
        }
    }

    .site-footer__widget {
        margin-top: 0;
        padding-bottom: 18px;
    }

    .footer-newsletter__form {
        text-align: center;
    }

    .footer-newsletter__text {
        margin-bottom: 12px;
    }

    .footer-newsletter__form-input {
        margin-bottom: 12px;
    }

    .row_footer_fm {
        border-top: 2px solid #b8b8b8;
        padding-top: 18px;
    }

    .col_block_fm {
        display: flex;
        justify-content: center;
    }

    .footer-contacts {
        text-align: left;
    }

    .site-footer__widget .info_fm span {
        font-size: 12px;
    }

    .site-footer__widget .logo_fm {
        margin-bottom: 14px;

        @media (max-width: 425px) {
            display: flex;
            justify-content: center;
            margin-bottom: 5px;
        }
    }

    .footer-links {
        text-align: left;
        margin-top: 0;
        display: flex;

        ul {
            margin-left: auto;

            li {
                margin-bottom: 2px;

                a {
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .nav_menu_fm {
        padding: 0 24px;
    }
}

.nav_modal_fm {
    max-width: 655px;

    .login_content_fm {
        max-width: 415px;
        margin: 0 auto;
        text-align: center;

        .sh_fm {
            margin-bottom: 16px;
        }

        .logn_title_fm {
            margin-top: 16px;
            font-size: 18px;
            color: #2d2d2d;
            text-align: center;
            font-weight: 400;
            margin-bottom: 16px;
        }

        form {
            margin-bottom: 32px;

            div {
                position: relative;
                margin-bottom: 16px;

                img {
                    position: absolute;
                    top: 16px;
                    left: 16px;
                }

                .login_inp_fm {
                    width: 100%;
                    height: 44px;
                    border: 1px solid #b8b8b8;
                    padding-left: 48px;
                    border-radius: 4px;
                }

                .login_inp_fm::placeholder {
                    font-size: 18px;
                    color: #959595;
                }
            }

            .forgot_pass_fm {
                color: #959595;
                text-align: right;
                font-size: 14px;

                span {
                    cursor: pointer;
                }
            }

            .rem_fm {
                text-align: left;
                margin-bottom: 16px;

                input {
                    border: 1px solid #b8b8b8;
                    margin-right: 8px;
                }

                span {
                    font-size: 14px;
                    color: #959595;
                }
            }

            button {
                width: 100%;
                height: 44px;
                font-size: 18px;
                color: #ffffff;
                font-weight: 400;
                border: none;
                background: #43b02a;
                border-radius: 4px;
            }
        }

        .sign_up_fm {
            .signup_title_fm {
                font-size: 14px;
                color: #959595;
                margin-bottom: 16px;
            }

            .social_reg_fm {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;

                .fb_reg_fm {
                    width: 100%;
                    height: 44px;
                    border: none;
                    background: #5a80d5;
                    border-radius: 4px;
                }

                .g_reg_fm {
                    width: 200px;
                    height: 44px;
                    border: none;
                    background: #de6152;
                    border-radius: 4px;
                }
            }

            .singup_link_fm {
                font-size: 18px;
                color: #707070;
                font-weight: 400;
                cursor: pointer;

                span {
                    color: #43b02a;
                }
            }
        }
    }
}

.lang_modal_fm {
    max-width: 600px;

    .modal-content {
        padding-top: 36px;
    }

    .lang_modal_tilte {
        color: #000;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }

    .language-modal-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        > div {
            width: 100%;
            padding: 44px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            opacity: 1;
            transition: opacity 0.3s ease;
            border-left: 0.5px solid #aaa;
            border-top: 0.5px solid #aaa;

            &:hover {
                cursor: pointer;
                opacity: 0.7;
                transition: opacity 0.3s ease;
            }

            @media (max-width: 425px) {
                flex-direction: row;
                justify-content: center;
            }

            span {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        @media (max-width: 425px) {
            flex-direction: column;
        }
    }
}

.login_inp_fm-2 {
    width: 100%;
    height: 44px;
    border: 1px solid #b8b8b8;
    padding-left: 48px;
    border-radius: 4px;
}

.log_ss_fm {
    div {
        input {
            padding-left: 17px !important;
        }
    }
}

.choose_fm {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #ebebeb;
}

.choose_fm .radio_fm {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 18px;
    color: #959595;
    display: flex;
    align-items: center;
}

.choose_fm .radio_fm input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.choose_fm .checkmark {
    position: absolute;
    left: 0;
    height: 13px;
    width: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b8b8b8;
    background-color: #ffffff;
    border-radius: 50%;
}

.choose_fm .radio_fm:hover input ~ .checkmark {
    background-color: #ffffff;
}

.choose_fm .radio_fm input:checked ~ .checkmark {
    background-color: #ffffff;
}

.choose_fm .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.choose_fm .radio_fm input:checked ~ .checkmark:after {
    display: block;
}

.choose_fm .radio_fm .checkmark:after {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #b8b8b8;
}

.badge-error {
    background: red;
    color: #fff;
}

.wishList_button_fm {
    border: none;
    background: transparent;
    outline: none;

    :focus {
        border: none;
        background: transparent;
        outline: none;
    }

    :active {
        border: none;
        background: transparent;
        outline: none;
    }

    path {
        fill: #43b02a;
    }
}

@media only screen and (max-height: 780px) and (min-width: 768px) {
    .sign_fm {
        margin: 50px auto !important;
        transform: translateY(-40%) !important;
    }
}

@media only screen and (width: 768px) {
    .sign_fm {
        margin: 50px auto !important;
        transform: translateY(-50%) !important;
    }
}

@media only screen and (max-width: 767px) {
    .login_fm {
        display: block;

        .sign_fm {
            height: auto !important;
            top: 0;
            transform: translateY(0);
        }

        .login_block_fm {
            height: 100%;
            width: 100%;
            padding: 8px;
            border-radius: 0;
            margin: 0;
            display: flex;
            align-items: center;

            .login_content_fm {
                width: 100%;
            }
        }
    }

    .login_fm .sh_fm {
        width: 150px;
    }
}

.new_logo_fm {
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-nav {
    border: none;
}

.address-remove-button {
    cursor: pointer;
}

.account_my_address {
    padding: 0px 20px;
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid #ccc;

    & > div {
        padding: 11px 15px;
        border-bottom: 1px solid #e8e8e8;

        & > div:last-child {
            margin-top: 5px;
            font-size: 14px !important;
            display: block;
            border: none;
            padding: 0 !important;

            @media screen and (max-width: 768px) {
                display: flex;
            }

            & > span:last-child {
                color: #f68139;
                margin-left: 10px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0;

        .pers_info_fm {
            padding: 0;
        }
    }
}

.personal-info {
    padding: 30px;
    background: #fff;

    .account__set_fm {
        margin-bottom: 20px;

        & > div {
            font-size: 14px;
            border-bottom: 2px solid #F0F0F0;
        }

        .cursor_fm {
            border: none;
        }
    }

    @media screen and (max-width: 991px) {
        padding: 16px;
    }

    @media screen and (max-width: 425px) {
        padding: 0;
    }
}

.account__set_fm {
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 500px) {
    .dashboard {
        .account__set_fm {
            flex-direction: column;
            height: unset !important;
            gap: 5px;
            border: 1px solid #f0f0f0;
            padding: 15px !important;

            div {
                width: 100%;
                text-align: start;

                &:nth-child(3) {
                    text-align: end;
                }
            }

            &:nth-child(2) {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}

.account__set_fm {
    div {
        font-size: 14px;
        color: #2d2d2d;
        font-weight: 400;
    }

    .account_change_fm {
        color: #43b02a;
        cursor: pointer;
    }
}

.account_modal_fm {
    max-width: 508px;

    form {

        div {
            font-size: 18px;
            color: #2d2d2d;
            font-weight: 400;
            margin-bottom: 28px;

            input {
                width: 100%;
                height: 44px;
                border: 1px solid #b8b8b8;
                border-radius: 4px;
                padding: 0 16px;

                &::placeholder {
                    font-weight: 400;
                    font-size: 18px;
                    color: #b8b8b8;
                }
            }

            .cancel_modal_fm {
                font-size: 18px;
                color: #43b02a;
                font-weight: 400;
                margin-right: 18px;
                margin-bottom: 0;
            }

            .change_modal_fm {
                margin-bottom: 0;
            }

            .change_modal_fm button {
                min-width: 87px;
                height: 36px;
                border-radius: 4px;
                background: #43b02a;
                color: #fff;
                font-weight: 400;
                border: none;
            }
        }

        .validation {
            margin-top: -28px;
        }

        .butt_block_fm {
            display: flex;
            text-align: right;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.pers_info_fm {
    display: flex;

    &.email {
        @media screen and (max-width: 425px) {
            flex-direction: column;
            gap: 20px;

            div {
                width: 100%;
                text-align: end;

                &:first-child {
                    text-align: start;
                }
            }
        }
    }
}

.pers_info_fm div {
    width: 50%;

    &:first-child {
        color: #3e3e3e;
    }

    &:last-child {
        text-align: left;
        color: black;
    }
}

.cursor_fm {
    cursor: pointer;
}

.table-responsive-sm {
    padding: 15px 32px;

    td {
        font-size: 14px !important;
    }

    .order_satus_fm {
        font-size: 16px !important;
    }

    @media (max-width: 767px) {
        th {

            &:nth-of-type(1),
            &:nth-of-type(3),
            &:nth-of-type(4) {
                display: none;
            }
        }

        td {

            &:nth-of-type(1),
            &:nth-of-type(3),
            &:nth-of-type(4) {
                display: none;
            }
        }
    }
}

a:hover {
    text-decoration: none;
}

.order_title_fm {
    padding-bottom: 32px;
    font-size: 38px;
    color: #2d2d2d;
    font-weight: 400;
    text-align: center;
}

.order_details_go_back {
    margin: 10px 0;

    span {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
            margin-right: 10px;
        }
    }
}

.order_card_fm {
    padding: 0 60px;
    overflow-y: auto;
    overflow-x: revert;
    background: #fff;
}

.order_card_fm table {
    min-width: 100%;
    border-collapse: collapse;
}

.order_card_fm table tr {
    border-bottom: 1px solid #ebebeb;
}

.order_detile_head th {
    padding: 37px 0;
}

.order_detile__columne {
    display: flex;
    align-items: center;
    padding: 16px 0;
}

.order_detile__columne * {
    margin-right: 30px;
}

.order_detilet__row th {
    text-align: center;
}

.order_detilet__row th:first-child {
    text-align: left;
}

.order_detile__row td {
    text-align: center;
}

.order_detile__row td:first-child {
    text-align: left;
}

.order_total_fm {
    margin-top: 38px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 100px;
}

.order_total_fm p {
    font-size: 18px;
    color: #43b02a;
    font-weight: 400;
    text-align: center;
}

.widget-filters__list {
    background: #fff;
    border-radius: 6px;
    cursor: pointer;

    padding-bottom: 0;

    .widget-filters {
        border-bottom: 2px solid #f0f0f0;

        &:last-child {
            border: none;
        }
    }
}

.cat_fm {
    display: flex;
    align-items: flex-start;
}

.filter_fms {
    border-bottom: 1px solid #f5f5f5;
}

.cat_menu_fm {
    margin-top: 34px;
    background: #fff;
    border-radius: 8px;
    padding: 0 16px;
    margin-right: 17px;
    box-shadow: 1px 2px 5px 0px rgba(68, 68, 68, 0.3);

    .cat_block1_fms {
        font-size: 18px;
        font-weight: 400;
        color: #2d2d2d;

        div {
            padding: 15px 16px;
        }
    }

    .cat_block1_fms a {
        font-size: 18px;
        font-weight: 400;
        color: #2d2d2d;

        div {
            padding: 15px 16px;
        }
    }

    .cat_block_fms {
        font-size: 18px;
        font-weight: 400;
        color: #2d2d2d;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    a:last-child div {
        border-bottom: none;
    }
}

.cat_list_fm {
    width: 65%;

    .cat_brand_fm {
        display: flex;
        align-items: center;

        div {
            font-size: 18px;
            font-weight: 400;
            color: #878787;
            margin-right: 30px;
            cursor: pointer;
        }

        div:first-child {
            color: #43b02a;
        }
    }

    .category_list_fm {
        margin-bottom: 38px;

        .cat_title_fm {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #2d2d2d;
            margin-bottom: 12px;

            .cat_line_fm {
                height: 1px;
                width: 100%;
                background: #f5f5f5;
                margin-left: 22px;
            }
        }

        .cat_items_fm {
            position: relative;

            .next_fm {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                width: 40px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                z-index: 10;
            }

            .item_fm {
                width: 100%;
                background: #fff;
                border-radius: 8px;
                margin-right: 8px;
                position: relative;

                .product_image_wrapper {
                    height: 320px;
                    display: flex;
                    position: relative;
                    border-radius: 8px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #DDDDDD;

                    @media(max-width: 499px) {
                        height: 220px;

                        .img_fm {
                            height: 160px;
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .product__box_price {
                        left: 0;
                        bottom: 0;
                        gap: 10px;
                        display: flex;
                        padding: 6px 15px;
                        position: absolute;
                        background: #E8E8E8;
                        flex-direction: row;
                        border-top-right-radius: 8px;
                        border-bottom-left-radius: 8px;

                        svg {
                            width: 24px;
                            height: 24px;
                        }

                        .box_price {
                            color: #FF0000;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                }

                .img_fm {
                    height: 220px;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;

                    img {
                        aspect-ratio: 1;
                        object-fit: contain;
                    }
                }

                .item_name_fm {
                    width: 100%;
                    margin-top: 12px;
                    color: #303030;
                    cursor: pointer;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    font-weight: 500;
                    height: 50px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    svg {
                        cursor: pointer;
                    }

                    @media (max-width: 425px) {
                        margin-top: 0;
                        font-size: 14px;
                        height: 40px;
                    }
                }

                .item_type_fm {
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 6px;
                }

                .dis_price_fm {
                    color: red;
                    font-size: 14px;
                    font-weight: 400;
                    position: relative;
                    text-decoration: line-through;

                    @media(max-width: 401px) {
                        font-size: 12px;
                    }

                    div {
                        position: absolute;
                        right: 0;
                        left: 0;
                        height: 1px;
                        background: red;
                        top: 48%;
                        transform: translateY(-50%);
                    }
                }

                .buy__more {
                    color: #9A9A9A;
                    font-size: 12px;
                    font-weight: 600;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .price_fm {
                    gap: 4px;
                    width: 100%;
                    color: black;
                    display: flex;
                    font-size: 20px;
                    flex-wrap: wrap;
                    min-height: 52px;
                    font-weight: 600;
                    align-items: center;
                    margin-bottom: 12px;
                    flex-direction: row;

                    @media(max-width: 401px) {
                        font-size: 18px;
                    }

                    .buy__more {
                        color: #9A9A9A;
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .price-old-fms {
                    margin-bottom: 12px;
                }

                .product_buttons_container {
                    gap: 10px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    @media (max-width: 1210px) and (min-width: 992px) {
                        flex-wrap: wrap;

                        .qount_fm {
                            max-width: 150px !important;
                        }
                    }

                    @media (max-width: 880px) and (min-width: 614px) {
                        flex-wrap: wrap;

                        .qount_fm {
                            max-width: 150px !important;
                        }
                    }

                    @media(max-width: 569px) {
                        flex-wrap: wrap;

                        .qount_fm {
                            max-width: 150px !important;
                        }
                    }

                    .qty_fm {
                        margin: 0;
                        max-height: 32px;
                        min-width: 60px;

                        .plus_min_fm {
                            width: 32px;
                            height: 32px;
                            color: #43b02a;
                            min-width: 32px;
                            border-radius: 30px;
                            background: transparent;
                            border: 1px solid #43b02a;
                        }

                        .qount_fm {
                            max-width: 60px;
                        }
                    }

                    .product_add_card {
                        button {
                            height: 36px;
                            font-size: 14px;
                            min-height: unset;
                        }
                    }
                }

                .qty_fm {
                    gap: 4px;
                    width: 100%;
                    display: flex;
                    max-width: 100%;
                    margin-top: 5px;
                    margin-bottom: 16px;
                    justify-content: space-between;

                    .plus_min_fm {
                        width: 36px;
                        height: 36px;
                        border-radius: 6px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #43b02a;
                        color: #fff;
                        text-align: center;

                        font-size: 23px;
                        font-style: normal;

                        line-height: normal;
                    }

                    .qount_fm {
                        height: 36px;
                        min-width: 60px;
                        max-width: 150px;
                        border-radius: 6px;
                        border: 1px solid #f5f5f5;

                        input {
                            width: 100%;
                            height: 100%;
                            border: none;
                            text-align: center;
                            font-size: 16px;
                        }

                        input:focus {
                            outline: none;
                            border: none;
                        }
                    }
                }

                .add_card_fm {
                    button {
                        width: 100%;
                        color: #fff;
                        height: auto;
                        border: none;
                        font-size: 18px;
                        min-height: 44px;
                        font-weight: 400;
                        border-radius: 2px;
                        background: #43B02A;
                    }
                }
            }
        }
    }
}

.inner_actions_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 14px;
}

.check_butt_fms {
    width: 234px;
    background: #f69c42;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
}

.btn_cart_rem_fm {
    background: transparent;
    width: 80px;
    height: 32px;
    outline: none;
    border: 1px solid #AAAAAA;
    border-radius: 2px;
    font-size: 12px;
    color: #121212;

    @media(max-width: 767px) {
        width: 120px;
        height: 36px;
    }
}

.btn_cart_rem_fm:focus {
    outline: none;
    border: none;
}


.cart_block_content {
    .qty_fm {
        border: 1px solid #AAAAAA;

        .qount_fm {
            max-width: 54px;
        }
    }
}

.cart_block_content-price {
    flex-direction: column;
}

.prod-box_price {
    color: #707070;
    font-size: 12px;
    font-weight: 400;
}

.qty_fm {
    width: 100%;
    display: flex;
    //justify-content: space-between;

    .plus_min_fm {
        width: 32px;
        height: 32px;
        //border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #379237;
        color: #fff;
        text-align: center;
        font-size: 23px;
        font-style: normal;
        line-height: normal;
    }

    .remove {
        background-color: white;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .qount_fm {
        width: 100%;
        max-width: 173px;

        input {
            width: 100%;
            height: 100%;
            border: none;
            text-align: center;
            font-size: 16px;
        }

        input:focus {
            outline: none;
            border: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
}

.add_card_fm {
    max-width: 250px;
    width: 100%;

    button {
        min-height: 44px;
        width: 100%;
        background: #43b02a;
        border: none;
        border-radius: 6px;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
    }
}

.product_add_card {
    max-width: 100%;
}

.product_modal_fm {
    max-width: 988px;
    padding: 0 8px;
}

.product_fm {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    margin-bottom: 68px;

    .product_img_fm {
        width: 49%;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 2;
        position: relative;
        background-size: contain;

        div {
            //position: absolute;

            right: 0;
            cursor: pointer;
        }
    }

    .product_info_fm {
        width: 49%;

        .product_name_fm {
            display: flex;
            align-items: center;
            justify-content: space-between;

            color: #303030;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 16px;
        }

        .product_description_fm {
            * {
                color: #303030;

                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .product_type_fm {
            font-size: 24px;
            color: #2d2d2d;
            font-weight: 400;
            margin-bottom: 16px;
        }

        .product_price_fm_for_1 {
            display: flex;
            gap: 6px;

            span,
            del {
                color: #ff1800;
                font-size: 18px;
                font-weight: 400;
            }
        }

        .product_price_fm_for_pack {
            display: flex;
            gap: 6px;

            span {
                color: #43b02a;
                font-size: 22px;
                font-weight: 600;

                @media screen and (max-width: 991px) {
                    font-size: 18px;
                }

                @media screen and (max-width: 425px) {
                    font-size: 17px;
                }
            }
        }

        .product_price_fm {
            font-size: 24px;
            color: #43b02a;
            font-weight: 400;
            margin-bottom: 16px;
        }

        .product_stock_fm {
            font-size: 18px;
            color: #2d2d2d;
            font-weight: 400;
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #f5f5f5;
        }

        .product_qty_fm {
            font-size: 18px;
            color: #9f9f9f;
            font-weight: 400;
            margin-bottom: 16px;
        }

        .share_fm {
            margin-top: 15px;

            button {
                width: 77px;
                height: 33px;
                border: none;
                border-radius: 6px;
                font-size: 18px;
                font-weight: 400;
                color: #fff;
            }

            .fb_share_fm {
                background: #5a80d5;
            }

            .g_share_fm {
                background: #de6152;
                margin-left: 16px;
            }
        }
    }
}

.related_items_fm {
    .title_fm {
        text-align: center;
        font-size: 30px;
        font-weight: bolder;
        color: #2d2d2d;
        margin-bottom: 16px;
    }

    .category_list_fm {
        .cat_title_fm {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #2d2d2d;
            margin-bottom: 12px;

            .cat_line_fm {
                height: 1px;
                width: 100%;
                background: #f5f5f5;
                margin-left: 22px;
            }
        }

        .cat_items_fm {
            width: 100%;
            position: relative;

            .prev_fm{
                position: absolute;
                left: 0px;
                top: 50%;
                transform: rotate(180deg) translateY(50%);
                cursor: pointer;
                z-index: 100;
            }

            .next_fm {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                z-index: 100;
            }

            .block-brands__item {
                padding: 0 15px 0 0;
            }

            .item_fm {
                width: 100%;
                background: #fff;
                border-radius: 8px;
                margin-right: 8px;
                position: relative;

                .product_image_wrapper {
                    height: 320px;
                    display: flex;
                    position: relative;
                    border-radius: 8px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #DDDDDD;

                    @media(max-width: 768px) and (min-width: 500px) {
                        height: 240px;

                        .img_fm {
                            height: 170px;
                        }
                    }

                    .wishList_button_fm {
                        top: 15px;
                        right: 9px;
                        z-index: 1;
                        position: absolute;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .product__box_price {
                        left: 0;
                        bottom: 0;
                        gap: 10px;
                        display: flex;
                        padding: 6px 15px;
                        position: absolute;
                        background: #E8E8E8;
                        flex-direction: row;
                        border-top-right-radius: 8px;
                        border-bottom-left-radius: 8px;

                        svg {
                            width: 24px;
                            height: 24px;
                        }

                        .box_price {
                            color: #FF0000;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                }

                .img_fm {
                    height: 220px;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;

                    img {
                        aspect-ratio: 1;
                        object-fit: contain;
                    }
                }

                .item_name_fm {
                    width: 100%;
                    margin-top: 12px;
                    color: #303030;
                    cursor: pointer;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    font-weight: 500;
                    height: 50px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    svg {
                        cursor: pointer;
                    }

                    @media (max-width: 425px) {
                        margin-top: 0;
                        font-size: 14px;
                        height: 40px;
                    }
                }

                .item_type_fm {
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 6px;
                }

                .dis_price_fm {
                    color: red;
                    font-size: 14px;
                    font-weight: 400;
                    position: relative;
                    text-decoration: line-through;

                    @media(max-width: 401px) {
                        font-size: 12px;
                    }

                    div {
                        position: absolute;
                        right: 0;
                        left: 0;
                        height: 1px;
                        background: red;
                        top: 48%;
                        transform: translateY(-50%);
                    }
                }

                .buy__more {
                    color: #9A9A9A;
                    font-size: 12px;
                    font-weight: 600;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .price_fm {
                    gap: 4px;
                    width: 100%;
                    color: black;
                    display: flex;
                    font-size: 20px;
                    flex-wrap: wrap;
                    min-height: 52px;
                    font-weight: 600;
                    align-items: center;
                    margin-bottom: 12px;
                    flex-direction: row;

                    @media(max-width: 401px) {
                        font-size: 18px;
                    }

                    .buy__more {
                        color: #9A9A9A;
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .price-old-fms {
                    margin-bottom: 12px;
                }

                .product_buttons_container {
                    gap: 10px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    @media (max-width: 1210px) and (min-width: 992px) {
                        flex-wrap: wrap;

                        .qount_fm {
                            max-width: 150px !important;
                        }
                    }

                    @media (max-width: 880px) and (min-width: 614px) {
                        flex-wrap: wrap;

                        .qount_fm {
                            max-width: 150px !important;
                        }
                    }

                    @media(max-width: 569px) {
                        flex-wrap: wrap;

                        .qount_fm {
                            max-width: 150px !important;
                        }
                    }

                    .qty_fm {
                        margin: 0;
                        max-height: 32px;
                        min-width: 60px;

                        .plus_min_fm {
                            width: 32px;
                            height: 32px;
                            color: #43b02a;
                            min-width: 32px;
                            border-radius: 30px;
                            background: transparent;
                            border: 1px solid #43b02a;
                        }

                        .qount_fm {
                            max-width: 60px;
                        }
                    }

                    .product_add_card {
                        button {
                            height: 36px;
                            font-size: 14px;
                            min-height: unset;
                        }
                    }
                }

                .qty_fm {
                    gap: 4px;
                    width: 100%;
                    display: flex;
                    max-width: 100%;
                    margin-top: 5px;
                    margin-bottom: 16px;
                    justify-content: space-between;

                    .plus_min_fm {
                        width: 36px;
                        height: 36px;
                        border-radius: 6px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #43b02a;
                        color: #fff;
                        text-align: center;

                        font-size: 23px;
                        font-style: normal;

                        line-height: normal;
                    }

                    .qount_fm {
                        height: 36px;
                        min-width: 60px;
                        max-width: 150px;
                        border-radius: 6px;
                        border: 1px solid #f5f5f5;

                        input {
                            width: 100%;
                            height: 100%;
                            border: none;
                            text-align: center;
                            font-size: 16px;
                        }

                        input:focus {
                            outline: none;
                            border: none;
                        }
                    }
                }

                .add_card_fm {
                    button {
                        width: 100%;
                        color: #fff;
                        height: auto;
                        border: none;
                        font-size: 18px;
                        min-height: 44px;
                        font-weight: 400;
                        border-radius: 2px;
                        background: #43B02A;
                    }
                }
            }
        }
    }
}

.order_mobile_fm,
.order_mobile_content_fm {
    padding: 0 8px;
    background: #fff;
}

.cat_page_fm,
.blog_page_fm,
.blog_inner_page_fm,
.about_us_fm {
    background: #fff;
    margin-top: -33px;
    padding-top: 33px;
}

.cart_page_fm {
    background: #fff;
    padding-bottom: 20px;
}

.cart_page_fm .cart_title_fm {
    font-size: 38px;
    color: #2d2d2d;
    text-align: center;
    font-weight: 400;
    margin-bottom: 30px;
}

.blog_comment_fm .add_com_blok {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 4px;
}

.cart_page_fm .cart_title_fm1 {
    font-size: 24px;
    color: #2d2d2d;
    text-align: center;
    font-weight: 400;
    margin-bottom: 30px;
}

.cart_table_fm {
    width: 100%;
}

.cart_page_fm .cart_block_title {
    //width: 120px;
    font-size: 16px;
    color: #2d2d2d;
    text-align: center;
    font-weight: 600;
}

.cart_page_fm .cart_block_title1 {
    text-align: left;
    padding: 16px 0 15px 24px;
}

.cart_page_fm .border_bottom {
    border-bottom: 1px solid #AAAAAA;
    width: 100%;
    //display: flex;
    //justify-content: space-between;
    display: grid;
    grid-template-columns: 4fr 9fr 9fr 5fr 5fr 2fr;
    align-items: center;

    > div:nth-child(3),
    > div:nth-child(5) {
        justify-self: center;
    }
    > div:nth-child(4) {
        width: 120px;
        justify-self: center;
    }
}

.cart-page_headings {
    height: 56px;
    background-color: #e7e7e7;

    > div:nth-child(3),
    > div:nth-child(5) {
        width: 100px
    }
    > div:nth-child(4) {
        width: 120px;
        justify-self: center;
    }
    >div:nth-child(6) {
        width: 80px;
    }
}

.cart_page_fm .cart_block_content {
    color: #121212;
    min-height: 152px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_page_fm .cart_block_content_img {
    width: 100px;
    padding: 16px 0;

    &.first_td_item {
        width: 130px;
    }
}

.cart-page_container {
    max-width: 1260px !important;
}

.cart_block_content > span {
    text-align: left;
    //padding-right: 20px;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.cart_page_fm .cart_block_content_cancel {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.cart_page_fm .cart_block_content_qty input {
    width: 87px;
    height: 36px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #f5f5f5;
}

.cart_page_fm .cart_block_content_qty input:focus {
    border: none;
    outline: none;
}

.cart_page_fm .cart_block_content_qty input[type="number"] {
    -moz-appearance: textfield;
}

.cart_page_fm .cart_block_content_qty input::-webkit-outer-spin-button,
.cart_page_fm .cart_block_content_qty input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cat_list_fm .cat_brand_fm {
    margin-bottom: 18px;
    overflow-y: auto;
}

.cat_list_fm .cat_brand_fm div {
    white-space: nowrap;
}

.open_def_fm {
    width: 30%;
}

.cart_page_fm .last_row_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
    justify-content: space-between;

    .column1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        cursor: pointer;

        svg {
            margin-right: 10px;
        }
    }

    .column2 {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        font-weight: 600;
        color: #000000;

        @media(max-width: 767px) {
            font-size: 18px;
            margin-bottom: 15px;
        }
    }

    @media(max-width: 767px) {
        flex-direction: column-reverse;
    }
}

.space {
    margin-left: 4px;
}

.cart_page_fm .buy_fm {
    text-align: center;
}

.buy_butt_fm {
    width: 190px;
    min-height: 40px;
    border-radius: 4px;
    background: #379237;
    font-size: 14px;
    color: #ffffff;
    border: none;

    @media(max-width: 767px) {
        width: 345px;
    }
    @media(max-width: 425px) {
        width: 100%;
    }
}

.blog_page_fm .blog_title_fm {
    text-align: center;
    font-size: 38px;
    color: #2d2d2d;
    font-weight: 400;
    margin-bottom: 32px;
}

.blog_page_fm .blog_list_fm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.blog_page_fm .blog_item_fm {
    padding: 16px;
    background: #fff;
    box-shadow: 0px 5px 12px 0px rgba(68, 68, 68, 0.1);
    width: 33%;
    border-radius: 4px;
    margin-bottom: 16px;
}

.blog_page_fm .blog_img_fm {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 196px;
}

.blog_page_fm .blog_date_fm {
    font-size: 14px;
    margin-bottom: 8px;
    color: #2d2d2d;
}

.blog_page_fm .blog_content_fm {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 400;
    color: #2d2d2d;
}

.blog_page_fm .read_more_fm {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 8px;
}

.blog_page_fm .read_more_fm {
    font-size: 14px;
    font-weight: 400;
    color: #43b02a;
}

.posts-view__pagination {
    margin-bottom: 64px;
}

.blog_inner_page_fm .inner_page_title_fm {
    font-size: 38px;
    color: #2d2d2d;
    font-weight: 400;
    margin-bottom: 16px;
}

.blog_inner_page_fm .blog_date_fm {
    display: flex;
    justify-content: space-between;
}

.blog_inner_page_fm .blog_date_fm span {
    font-size: 14px;
    margin-bottom: 8px;
    color: #2d2d2d;
}

.blog_inner_page_fm .inner_block_fm {
    display: flex;
    justify-content: space-between;
}

.blog_inner_page_fm .inner_page_left_fm {
    width: 760px;
    margin: 0 auto;
}

.blog_inner_page_fm .inner_page_img_fm {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 433px;
    margin-bottom: 20px;
}

.blog_inner_page_fm .inner_social_icon_fm img {
    margin-left: 8px;
}

.blog_inner_page_fm .inner_page_content_fm {
    font-size: 18px;
    color: #2d2d2d;
    font-weight: 500;
    margin-bottom: 32px;
}

.blog_inner_page_fm .inner_line_fm {
    height: 2px;
    background: #ebebeb;
    margin-bottom: 32px;
}

.blog_inner_page_fm .top_fm {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 400;
    color: #636363;
}

.blog_inner_page_fm {
    padding-bottom: 64px;
}

.blog_inner_page_fm .inner_page_right_fm {
    height: 433px;
    padding-left: 16px;
    width: 370px;
    padding-top: 22px;
}

.blog_inner_page_fm .inner_page_right_fm .inner_page_right_title_fm {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #2d2d2d;
}

.blog_inner_page_fm .inner_page_related_fm {
    height: 374px;
    overflow-y: scroll;
}

.inner_page_related_fm::-webkit-scrollbar {
    width: 10px;
}

.inner_page_related_fm::-webkit-scrollbar-track {
    scrollbar-width: 1px;
    background-clip: content-box;
    background-color: #ebebeb;
    border: 4px solid transparent;
}

.inner_page_related_fm::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    border-radius: 10px;
}

.inner_page_right_fm .inner_line_fm {
    margin: 0 0 16px 0;
}

.inner_page_right_fm .inner_page_related_item_fm {
    display: flex;
    padding-right: 18px;
    margin-bottom: 16px;
}

.inner_page_right_fm .inner_page_related_item_fm .img_fm {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 114px;
    height: 114px;
    min-width: 114px;
    margin-right: 8px;
}

.inner_page_right_fm .inner_page_related_item_fm .content_title {
    font-size: 14px;
    color: #2d2d2d;
    font-weight: 500;
}

.about_us_fm video {
    width: 100%;
    height: 650px;
    background: black;
    margin-bottom: 32px;
}

.about_us_fm .title_about_fm {
    font-size: 38px;
    font-weight: 400;
    color: #2d2d2d;
    text-align: center;
    margin-bottom: 32px;
}

.menu_page_title_fm {
    font-size: 38px;
    font-weight: 400;
    color: #2d2d2d;
    text-align: center;
    margin-bottom: 32px;
}

.about_us_fm .text_about_fm {
    font-size: 24px;
    color: #2d2d2d;
    margin-bottom: 64px;
}

.dropdown-group {
    display: none;
}

.dropdown-group.active {
    display: block;
}

.title-arrow-flex {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.send_contact_fm {
    padding: 0px 15px;
    height: 44px;
    font-size: 18px;
    color: #fff;
    background: #43b02a;
    font-weight: normal;
    border: none;
    border-radius: 4px;
    width: 236px;
}

.primary_fm {
    background: #43b02a;
    color: #fff;
    border-radius: 4px;
    border: none;
}

//.slick-arrow.slick-prev,
//.slick-arrow.slick-next {
//    display: none !important;
//}

.product_fm .product_info_fm .product_price_fm .dis_price_fm {
    font-size: 24px;
    font-weight: 400;
    color: #2d2d2d;
    position: relative;
    margin-bottom: 6px;
    margin-right: 22px;
}

.product_fm .product_info_fm .product_price_fm .dis_price_fm div {
    position: absolute;
    right: 0;
    left: 0;
    height: 1px;
    background: red;
    top: 48%;
    transform: translateY(-50%);
}

.product_lists_fm {
    .cat_items_fm {
        display: grid;
        grid-template-columns: repeat(3, calc((100% - 52px) / 3));
        gap: 35px 26px;

        @media (max-width: 991px) {
            margin-top: 20px;
        }

        @media (max-width: 613px) {
            grid-template-columns: repeat(2, calc((100% - 26px) / 2));
        }

        @media (max-width: 425px) {
            gap: 10px;
            grid-template-columns: repeat(2, calc((100% - 10px) / 2));
        }
    }

    .cat_menu_fm {
        width: 220px;
        padding: 16px;
    }

    .cat_list_fm {
        width: 100%;
        padding-bottom: 25px;
    }

    .block-brands__item {
        width: 100%;
        max-width: 297px;
        position: relative;

        .wishList_button_fm {
            top: 15px;
            right: 9px;
            z-index: 1;
            position: absolute;
        }
    }

    .cat_menu_fm div {
        display: block;
        border: none;
        padding-top: 0;
        padding-bottom: 12px;
    }

    .product_menu_type_fm {
        font-size: 18px;
        font-weight: 400;
        color: #2d2d2d;
    }

    .product_menu_fm {
        border-bottom: 1px solid #f5f5f5 !important;
    }

    .product_menu_text_fm {
        font-size: 14px;
        font-weight: 500;
        color: #2d2d2d;
        display: flex !important;
        align-items: center;
        justify-content: unset;
    }

    .product_menu_text_fm input {
        margin-right: 8px;
    }
}

.blog_inner_page_fm .blog_comment_fm {
    min-height: 337px;
    padding: 20px 20px;
    border-top: 1px solid #c4c4c4;
    margin-bottom: 50px;
}

.blog_inner_page_fm .blog_comment_fm .comment_left_fm {
    position: relative;
}

.blog_inner_page_fm .blog_comment_fm .comment_left_fm .comment_title_fm {
    font-size: 26px;
    color: #2d2d2d;
    margin-bottom: 30px;
    font-weight: 400;
}

.blog_inner_page_fm .blog_comment_fm .comment_left_fm textarea {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #c4c4c4;
    padding: 20px;
    font-size: 18px;
    color: #2d2d2d;
    margin-bottom: 30px;
}

.blog_inner_page_fm .blog_comment_fm .comment_left_fm textarea::placeholder {
    font-size: 18px;
    color: #d5d1d1;
}

.blog_inner_page_fm .blog_comment_fm .comment_left_fm .comment_butt_fm {
    display: flex;
    justify-content: flex-end;
}

.blog_inner_page_fm .blog_comment_fm .comment_left_fm .comment_butt_fm button {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background: #43b02a;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm {
    padding: 0 15px;
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 16px;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm::-webkit-scrollbar {
    width: 10px;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm::-webkit-scrollbar-track {
    scrollbar-width: 1px;
    background-clip: content-box;
    background-color: #ebebeb;
    border: 4px solid transparent;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    border-radius: 10px;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm > div {
    margin-top: 14px;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm .info_fm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm .info_fm .name_fm {
    font-size: 16px;
    font-weight: 500;
    color: #2d2d2d;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm .info_fm .comment_date_fm {
    font-size: 14px;
    font-weight: 500;
    color: #b8b8b8;
}

.blog_inner_page_fm .blog_comment_fm .comment_right_fm .content_comment_fm {
    font-size: 18px;
    font-weight: 500;
    color: #636363;
}

.block_contentfm {
    display: flex;
    justify-content: space-between;
    width: 45%;
}

.input-error_fm {
    border-radius: 4px;
    border: 1px solid red;
}

.error,
.forgot_password_error {
    color: red;
}

.forgot_password_successful {
    color: #43b02a;
}

.menu_page_fm {
    background: #fff;
    margin-top: -32px;
    padding-top: 32px;
    padding-bottom: 25px;
}

@media only screen and (max-width: 451px) {
    .block_contentfm {
        display: flex;
        flex-direction: column;
    }

    .cart_page_fm .cart_block_content {
        min-height: unset;
    }
}

@media only screen and (max-width: 768px) {

    .cat_list_fm {
        width: 100%;
    }

    .open_def_fm {
        width: 100%;
        position: relative;
    }

    .cat_fm {
        display: block;
    }

    .header_line_fm {
        height: 1px;
        background: #43b02a;
        margin: 0 8px;
    }

    .menu_icon_fm {
        margin-bottom: 16px;
        display: inline-block;
    }

    .body_fm {
        margin: 0;
        height: 100%;
        overflow: hidden;
    }

    .open_menu_fm {
        z-index: 115;
        left: 0;
        top: -34px;
        bottom: 0;
        position: absolute;
        width: 280px;
        border-bottom-left-radius: unset;
        border-top-left-radius: unset;
        overflow-x: auto;
    }

    .open_menu_fm::-webkit-scrollbar {
        width: 10px;
    }

    .open_menu_fm::-webkit-scrollbar-track {
        scrollbar-width: 1px;
        background-clip: content-box;
        background-color: #ebebeb;
        border: 4px solid transparent;
    }

    .open_menu_fm::-webkit-scrollbar-thumb {
        background-color: #ebebeb;
        border-radius: 10px;
    }

    .back_open_menu_fm {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 150;
        left: 0;
        background: rgba(68, 68, 68, 0.4);
        right: 0;
        display: block !important;
    }

    .close_menu_fm {
        position: fixed;
        z-index: 200;
        top: 16px;
        right: 8px;
    }

    .product_fm {
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding-bottom: 24px;
        border-bottom: 1px solid #f5f5f5;
    }

    .related_items_fm .title_fm {
        font-size: 18px;
    }

    .product_fm .product_img_fm {
        width: 100%;
        //height: 250px;
        margin-bottom: 12px;
    }

    .product_fm .product_info_fm {
        padding: 0 15px;
        width: 100%;
    }

    .product_fm .product_info_fm .product_name_fm {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .product_fm .product_info_fm .product_type_fm {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .product_fm .product_info_fm .product_price_fm {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .product_fm .product_info_fm .product_price_fm .dis_price_fm,
    .product_fm .product_info_fm .product_stock_fm {
        font-size: 16px;
    }

    .product_fm .product_info_fm .product_qty_fm {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .product_info_fm .qty_fm {
        width: 100%;
    }

    .product_info_fm .qty_fm .plus_min_fm {
        width: 33px;
        height: 33px;
    }

    .product_info_fm .qty_fm .qount_fm {
        height: 33px;
        width: 100%;
        font-size: 14px;
    }

    .product_info_fm .add_card_fm button {
        height: 33px;
        width: 100%;
        font-size: 14px;
    }
    .account__set_fm {
        padding: 0 8px;
    }

    .account_modal_fm form div input::placeholder {
        font-size: 14px;
    }

    .account_modal_fm form div .cancel_modal_fm {
        font-size: 14px;
    }

    .account_modal_fm form div .change_modal_fm button {
        font-size: 14px;
    }

    .account__set_fm div {
        font-size: 14px;
    }

    .account__set_fm div:last-child {
        text-align: right;
    }

    .order_title_fm {
        font-size: 18px;
        padding: 0;
    }

    .order_mobile_title_fm {
        font-size: 12px;
        font-weight: 900;
        color: #2d2d2d;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .order_detile__columne {
        font-size: 12px;
        font-weight: 900;
    }

    .order_mobile_content_fm {
        display: flex;
        justify-content: space-between;
        height: 30px;
        align-items: center;
    }

    .order_card_fm {
        border-top: 1px solid #f5f5f5;
        padding: 0;
    }

    .order_mobile_content_fm div {
        font-size: 12px;
        font-weight: 900;
    }

    .order_total_fm {
        margin-top: 10px;
        margin-bottom: 0;
        padding-right: 8px;
    }

    .order_mobile_title_fm {
        padding-top: 8px;
    }

    .order_total_fm p {
        text-align: right;
        margin-bottom: 8px;
        font-size: 12px;
    }

    .order_mobile_list_fm {
        padding: 12px 10px;
    }

    .order_mobile_list_fm .order_mobile_content_fms {
        border-bottom: 1px solid #f5f5f5;
    }

    .order_mobile_list_fm .order_mobile_content_fms > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .order_mobile_list_fm .order_mobile_content_fms > div:first-child {
        margin-top: 10px;
    }

    .order_mobile_list_fm .order_mobile_content_fms > div div {
        font-size: 14px;
        color: #2d2d2d;
        font-weight: 600;
    }

    .order_mobile_list_fm .order_mobile_content_fms .order_del_fm {
        color: #43b02a !important;
    }

    .order_mobile_list_fm .order_mobile_content_fms .order_cancel_fm {
        color: #de6152 !important;
    }

    .product_modal_fm {
        margin: 0;
        padding: 0;
    }

    .nav_modal_fm .login_content_fm .sh_fm {
        margin-bottom: 10px;
        margin-top: 15px;
        width: 95px;
    }

    .nav_modal_fm .login_content_fm .logn_title_fm {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .nav_modal_fm .login_content_fm form div .login_inp_fm,
    .nav_modal_fm .login_content_fm form div .login_inp_fm::placeholder {
        font-size: 14px;
    }

    .nav_modal_fm .login_content_fm form div {
        margin-bottom: 8px;
    }

    .nav_modal_fm .login_content_fm form .forgot_pass_fm {
        font-size: 12px;
        margin-bottom: 8px;
    }

    .nav_modal_fm .login_content_fm form .rem_fm span {
        font-size: 12px;
    }

    .nav_modal_fm .login_content_fm form .rem_fm {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    .nav_modal_fm .login_content_fm form button {
        font-size: 14px;
    }

    .nav_modal_fm .login_content_fm form {
        margin-bottom: 10px;
    }

    .nav_modal_fm .login_content_fm .sign_up_fm .signup_title_fm {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .nav_modal_fm .login_content_fm .sign_up_fm .singup_link_fm {
        font-size: 14px;
        margin-bottom: 40px;
    }

    .choose_fm .radio_fm {
        font-size: 12px;
    }

    .blog_page_fm .blog_list_fm {
        display: block;
    }

    .blog_page_fm .blog_item_fm {
        width: 100%;
    }

    .blog_inner_page_fm .inner_page_title_fm {
        font-size: 20px;
    }

    .blog_inner_page_fm .inner_block_fm {
        display: block;
    }

    .blog_inner_page_fm .inner_page_left_fm {
        width: 100%;
    }

    .blog_inner_page_fm .inner_page_img_fm {
        height: 350px;
    }

    .blog_inner_page_fm .blog_date_fm span {
        font-size: 12px;
    }

    .blog_inner_page_fm .inner_page_content_fm {
        font-size: 16px;
    }

    .blog_inner_page_fm .inner_page_right_fm {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }

    .inner_page_right_fm .inner_page_related_item_fm .content_title {
        max-width: 100%;
        width: 100%;
    }

    .inner_page_right_fm .inner_page_related_item_fm .img_fm {
        width: 92px;
        height: 92px;
    }

    .blog_inner_page_fm .top_fm {
        font-size: 16px;
    }

    .about_us_fm video {
        height: 350px;
        margin-bottom: 13px;
    }

    .about_us_fm .title_about_fm {
        margin-bottom: 12px;
        font-size: 20px;
        color: #2d2d2d;
    }

    .menu_page_title_fm {
        margin-bottom: 12px;
        font-size: 20px;
        color: #2d2d2d;
        text-align: center;
    }

    .about_us_fm .text_about_fm {
        font-size: 16px;
    }

    .search__body form {
        border: none;
        background: transparent;
    }

    .search__body input {
        border: 1px solid #e2e2e2;
    }

    .product_lists_fm .cat_list_fm {
        width: 100%;
    }

    .cat_list_fm .category_list_fm {
        margin-bottom: 0;
        padding-bottom: 18px;
    }

    .site-footer__bottom {
        padding: 10px 0px 74px;
    }

    .blog_inner_page_fm .blog_comment_fm {
        flex-wrap: wrap;
    }

    .blog_inner_page_fm .blog_comment_fm .comment_left_fm {
        width: 100%;
    }

    .blog_inner_page_fm .blog_comment_fm .comment_right_fm {
        width: 100%;
        margin-top: 20px;
    }

    .blog_inner_page_fm .blog_comment_fm .comment_left_fm .comment_title_fm {
        font-size: 20px;
        margin-bottom: 18px;
    }

    .blog_inner_page_fm .blog_comment_fm .comment_left_fm textarea {
        margin-bottom: 18px !important;
    }

    .blog_inner_page_fm .blog_comment_fm .comment_left_fm textarea,
    .blog_inner_page_fm .blog_comment_fm .comment_left_fm textarea::placeholder,
    .blog_inner_page_fm .blog_comment_fm .comment_left_fm .comment_butt_fm button,
    .blog_inner_page_fm .blog_comment_fm .comment_right_fm .content_comment_fm {
        font-size: 14px;
    }

    .blog_inner_page_fm .blog_comment_fm {
        padding: 10px;
    }
}

@media only screen and (max-width: 576px) {
    .home_category_fm .list_fm .item_fm {
        background-size: 160%;
    }

    .blog_page_fm .blog_content_fm {
        font-size: 14px;
    }

    .blog_page_fm .blog_title_fm {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .product_lists_fm .block-brands__item .item_fm {
        margin: 0 auto;
    }

    .related_items_fm .block-brands__item {
        padding: 0;
    }
}

@media only screen and (max-width: 350px) {
    .qty_fm {
        max-width: 100%;
        width: 100%;
    }

    .product_fm .product_info_fm .block_1 {
        display: block;

        .qty_fm {
            justify-content: unset !important;
        }
    }

    .add_card_fm button {
        width: 100%;
    }
}

.cart_mob_title_fm {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart_mob_prod_fm {
    height: auto;
    display: flex;
    cursor: pointer;
    //align-items: center;
    //justify-content: center;
}

.product_data_mob {
    gap: 8px;
    display: flex;
    flex-direction: column;
    margin: 15px 15px 0 0;
}

.image_container_mob {
    width: 80px;
    height: 80px;
    display: flex;
    min-width: 80px;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 20px 15px 15px 15px;
}

.product_name_mob {
    max-width: 450px;

    span {
        color: #121212;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.price_mob {
    display: flex;
    color: #121212;
    font-weight: 700;
    flex-direction: row;
}

.quantity_mob {
    width: 138px;

    .qty_fm {
        border: 1px solid #AAAAAA;
        background-color: white;
        justify-content: space-between;

        .qount_fm {
            width: unset;
        }
    }

    .plus_min_fm {
        width: 36px;
        height: 36px;
    }
}

.cart_mob_title_fm > div {
    padding: 8px 0;
    font-size: 12px;
    color: #2d2d2d;
}

.cart_mob_title_fm_last_row {
    margin: 10px 0;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.cart-total_mob {
    display: flex;
    flex-direction: row;
    color: #121212;
    font-weight: 700;
}

.cart_mob_blok_fm {
    height: auto;
    margin-bottom: 15px;
    background-color: #ededed;
    border-bottom: 1px solid #f5f5f5;
}

@media only screen and (max-width: 768px) {
    .qty_fm .plus_min_fm {
        width: 33px;
        height: 33px;
    }
}

@media only screen and (max-width: 575px) {
    .cart_page_fm .cart_block_content_qty {
        width: 52px;
        height: 19px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cart_page_fm .cart_title_fm {
        margin-bottom: 25px;
        text-align: left;
    }

    .cart_page_fm .cart_title_fm,
    .cart_page_fm .total_price_fm {
        font-size: 16px;
    }

    .cart_page_fm .total_price_fm {
        padding: 16px 0 24px;
    }
}

.cat_blocks_fms {
    background: #fff;

    @media screen and (min-width: 601px) {
        .page-header__title {
            text-align: start;
        }
    }
}

.product_lists_fme {
    width: 100%;
}

.Toastify__close-button {
    color: #333;
}

.Toastify__toast--success {
    display: flex;
    justify-content: center;
    background: #fff;
    color: #333;
    text-align: center;
    font-size: 16px;
}

.Toastify__toast-body {
    margin: auto 0;
    max-width: 85%;
    width: 100%;
    display: flex;
    align-items: center;
}


.brands_block_fm {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;

    &.catalog_page {
        margin: 10px;

        & > a {
            transition: all 0.3s ease-in;
            height: 80px;
            width: 100%;
            cursor: pointer;
            box-shadow: 0px 0px 1px 1px rgba(0, 11, 255, 0.2);

            &.active {
                box-shadow: 0px 0px 1px 1px #43b02a;
            }

            &:hover {
                transition: all 0.3s ease-in;
                transform: scale(1.09);
            }

            div {
                height: 100%;

                img {
                    margin: 0 auto;
                    width: 60%;
                    object-fit: contain;
                }

                p {
                    font-size: 13px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.brands_fm {
    background: #fff;
    margin-top: -35px;
    padding-bottom: 35px;
}

.brands_block_fm * {
    display: block;
    text-align: center;
}

.brands_block_fm a {
    height: 150px;
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1%;
    box-shadow: 0px 4px 8px 3px rgba(0, 11, 255, 0.2);
    border-radius: 6px;
    color: #2d2d2d;

    div {
        img {
            width: 60% !important;
            object-fit: contain !important;
            margin: 0 auto !important;
        }
    }
}

@media only screen and (max-width: 768px) {
    .brands_fm {
        margin-top: -18px;
        padding-top: 16px;
    }

    .brands_block_fm a {
        width: 48%;
    }
}

.savings_fms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    label {
        width: 100%;
    }
}

.users-funcButton_fm {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.users-funcButton_fm > span {
    cursor: pointer;
}

.add-users_fm {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #43b02a;
    cursor: pointer;
}

.add-users_fm > span:last-child {
    margin-left: 8px;
}

.card-body {
    .form-group.adresses {
        & > div {
            display: flex;
            align-items: center;

            label {
                margin-left: 10px;
            }
        }
    }
}

.sync-products-cart-page {
    h2 {
        text-align: left;
        font-size: 24px;
        color: #2d2d2d;
        display: flex;
        align-items: center;

        svg {
            margin-left: 20px;
        }
    }

    & > div {
        height: 0;
        overflow: hidden;
        border: 0px !important;

        table {
            tbody {
                tr {
                    border: none !important;
                    border-bottom: 1px solid #ccc !important;

                    &:last-child {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }

    &.open {
        & > div {
            height: max-content;
            border: 0px !important;
        }
    }
}

.contact-customhtml-fms {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 100px;
}

.fieldset {
    border: 0;
    margin: 0 0 40px;
    padding: 0;
}

.fieldset-params-fms {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.field .email .required {
    width: 31%;
    display: inline-block;
    margin-right: 31px;
    width: 30%;
}

.label-fms {
    margin-bottom: 20px;
}

.contact-label-fms:first-child {
    padding-right: 20px;
}

.red-fms {
    color: red;
}

.control {
    width: 100%;
}

.input-text:focus {
    color: #495057;
    background-color: #fff;

    outline: 0;
    box-shadow: 0 0 0 2px #43b02a inset;
}

.error {
    font-size: 13px;
    color: red;
}

.field input {
    border: 1px solid #fafafa;
    background: #fafafa;
    padding-left: 28px;
    box-shadow: 0 0 0 2px #e5e5e5 inset;
}

.comment {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.comment-fms textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 100%;
    border: 1px solid #fafafa;
    background: #fafafa;
    width: 100%;
    height: 55px;
    padding: 5px 15px;
    box-shadow: 0 0 0 2px #e5e5e5 inset;
}

.submit-primary-fms {
    float: right;
    padding: 12px 70px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    border: none !important;
    background: #43b02a;
}

.submit-primary-fms > span {
    color: white;
}

.topbar__item-title-fms {
    color: #3d464d;
}

.popUpwrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    padding: 20px;
}

.popUp {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 628px;
    width: 100%;
    max-height: 400px;
    height: auto;
    padding: 24px;
    background: #ffffff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;

    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #121212;
    }

    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-transform: capitalize;
        color: #303030;
    }

    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #303030;
    }
}

.popUp-svg {
    display: flex;
    justify-content: center;
}

.close-wrapper {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.comming_soon_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logo_comming {
    max-width: 378px;
    width: 100%;

    h1 {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 54px;
        line-height: 84px;
        color: #121212;
    }
}

.info_comming {
    margin-top: 70px;

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        li {
            list-style: none;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 34px;
            color: #000000;
        }
    }
}

@media (max-width: 570px) {
    .logo_comming {
        h1 {
            font-size: 45px;
        }
    }

    .info_comming {
        ul {
            display: flex;
            flex-direction: column;

            li {
                font-size: 24px;

                &:nth-child(even) {
                    display: none;
                }
            }
        }
    }
}

.share_buttons {
    display: flex;
    gap: 14px;
}

.dialog_modal {
    max-width: 600px;
}

.dialog_content {
    padding: 40px;
}

.dialog_title {
    text-align: center;
    color: #000;
    font-size: 40px;
}

.dialog_actions {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;
    padding-top: 40px;
    border-top: 1px solid #aaa;

    > div {
        max-width: 240px;
        width: 100%;
        padding: 13px;
        text-align: center;
        cursor: pointer;
        opacity: 1;
        transform: opacity 0.3s ease;

        &:hover {
            opacity: 0.8;
            transform: opacity 0.3s ease;
        }
    }
}

.posotive_answer {
    background: #43b02a;

    span {
        color: #fff;
        font-size: 18px;
    }
}

.negative_answer {
    background: #ffffff;
    border: 1px solid #000;

    span {
        color: #121212;
        font-size: 18px;
    }
}

.pswp__img {
    object-fit: contain;
}

.product_attributes {
    //.product__meta {
    //    margin-top: 15px;
    //    display: flex;
    //    flex-direction: column;
    //    margin-bottom: 15px;

        .attr_fms {
            margin-top: 10px;
        }

        .attr_fms,
        .attr_color_fms {
            & > div > p {
                margin: 5px 0px !important;
            }

            .color-attr_fm,
            .other-attr_fm {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: repeat(8, 30px);

                .filter-color__item {
                    margin: 0px !important;
                }
            }
        }
    //}

    .other-attr_fm {
        label {
            display: flex;

            &:first-child {
                margin-left: 0 !important;
            }

            & > span:last-child {
                margin-left: 5px;
            }

            justify-content: space-between;
        }
    }

    .color-attr_fm {
        //.input-check-color__body {
        //    border: 1px solid #d7d7d7 !important;
        //}

        .filter-color__item {
            margin: unset !important;
        }
    }
}

.other-list__input {
    padding-right: 5px;
}

.other-list__item {
    padding-right: 15px;
    cursor: pointer;
}

.other-radio__input {
    width: 10px;
    cursor: pointer;
}

.alternative-variants-block {
    p {
        margin: 5px 0 !important;
    }
}

.animate-variants {
    position: relative;
    height: 22px;
    width: 150px;

    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        animation: anim 1s ease-in-out infinite alternate;
        background-color: #cecece;
    }
}

@keyframes anim {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 0.5;
    }
}

.searched_word {

    width: 100%;
    text-align: center;

    h1, span {
        color: #000;
        font-size: 24px;
        font-style: normal;
        line-height: normal;
    }

    h1 {
        font-weight: 700;
        display: flex;
        justify-content: center;
        gap: 5px;
    }

    span {
        font-weight: 400;
    }
}

.dangerous-html-page {
    margin-top: 20px;
}

.image_container {
    display: grid;
    grid-template-columns: repeat(4, calc((100% - 60px) / 4));
    gap: 20px;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(3, calc((100% - 40px) / 3));
    }

    @media only screen and (max-width: 425px) {
        grid-template-columns: repeat(2, calc((100% - 10px) / 2));
        gap: 10px;
    }

    .image {
        width: 100%;

        img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: contain;
        }
    }
}

.map-block {
    @media(max-width: 768px) {
        flex-wrap: wrap;
    }
}
