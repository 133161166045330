/*
// .page-header
*/
@import '../variables';
@import '../functions';


.page-header {}
.page-header__container {
    padding-bottom: 32px;
}
.page-header__breadcrumb {
    padding-top: 16px;
}
.page-header__title {
    padding-top: 20px;
    text-align: center;

    h1 {
        margin-bottom: 0;
        font-size: 32px;
        font-weight: 600;
        color: #000000;
    }
}

.checkout_fm{
    background: #fff;
    margin-top: -33px;
    padding-bottom: 30px;
}

@media  (max-width: 768px) {
    .checkout_fm{
        margin-top: -15px;
        padding-top: 15px;
    }
}


@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .page-header__title {
        padding-top: 20px;

        h1 {
            margin-bottom: 0;
        }
    }
}
@media (max-width: breakpoint(sm-end)) {
    .page-header__container {
        padding-bottom: 24px;
    }
    .page-header__title {
        padding-top: 15px;

        h1 {
            margin-bottom: 0;
            font-size: 24px;
        }

    }
}
