/*
// .site-footer
*/
@import '../variables';
@import '../functions';


.site-footer {
    border-top: 4px solid #43B02A;
    min-height: 328px;
    padding: 34px 42px 0 42px;

    .container_fm {
        max-width: 100%;
        width: 100%;
    }
}


.footer-in-home {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #379237;

    padding: 6px 42px;

    @media screen and (max-width: 767px) {
        padding: 6px 42px 70px;
    }

    ul {
        width: 100%;
        margin: unset;
        padding: unset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        text-wrap: nowrap;


        @media (max-width: 768px) {
            flex-direction: column;
            gap: 5px;
        }

        li {
            list-style: none;
            // a{
            display: flex;
            align-items: center;
            gap: 20px;
            // span{
            font-size: 18px;
            color: #FFFFFF;
            font-weight: 500;

            a {
                color: #FFFFFF !important;
                text-decoration: underline;
            }

            @media (max-width: 500px) {

                font-size: 14px;
            }

            // }
            // }

        }

        @media (max-width: 360px) {

            gap: 10px;
        }
    }
}

.site-footer__widgets {
    padding: 20px 0 48px;
}

.site-footer__bottom {
    min-height: 66px;
    border-top: 2px solid #B8B8B8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap: wrap;
    @media (max-width: 991px) {
        padding-top: 10px;
        flex-direction: column;
    }
}

.site-footer__copyright {

    color: #121212;

    font-size: 18px;

    font-weight: 500;
    @media (max-width: 326px) {
        font-size: 16px;
    }
    // margin: 0 auto;
}

.site-footer__developed_by {

    display: flex;
    gap: 6px;

    span {
        color: #121212;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    a {
        color: #121212;
        text-decoration-line: underline;
    }
}

.site-footer__payments {
    img {
        max-width: 100%;
    }
}

.site-footer__widget {
    & + & {
        margin-top: 24px;
    }
}


@media (max-width: breakpoint(sm-end)) {
    .site-footer__widgets {
        padding-bottom: 48px;
    }

    .site-footer__bottom {
        padding: 20px 0 24px;
        flex-wrap: wrap;
        height: auto;
    }

    .site-footer__payments {
        order: -1;
    }

    .site-footer__copyright + .site-footer__payments {
        margin-bottom: 24px;
    }

    .site-footer__copyright,
    .site-footer__payments {
        width: 100%;
        text-align: center;
    }
}
