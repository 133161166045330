/*
// .departments
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


$local-transition-duration: .25s;


.departments {
    width: 255px;
    height: $departments-height;
    color: $departments-font-color;
    position: relative;
}
.departments__button {
    border: none;
    background: transparent;
    color: inherit;
    font-size: 15px;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: default;
    font-weight: $font-weight-medium;
    padding: 0;

    @include direction {
        #{$padding-inline-end}: 32px;
        #{$padding-inline-start}: 47px;
        text-align: $inline-start;
    }

    &:focus {
        outline: none;
    }
}
.departments__button-icon {
    position: absolute;
    top: calc(50% - 7px);
    fill: $departments-icon-color;
    transition: fill .2s;

    @include direction {
        #{$inset-inline-start}: 16px;
    }
}
.departments__button-arrow {
    position: absolute;
    top: calc(50% - 3px);
    fill: $departments-icon-color;
    transition: fill .2s, transform .2s;

    @include direction {
        #{$inset-inline-end}: 13px;
    }
}
.departments__body {
    width: 100%;
    padding-top: $departments-height;
    position: absolute;
    background: $departments-bg;
    box-shadow: $departments-shadow;
    border-radius: 2px;
    top: 0;
}
.departments__links {
    position: relative;

    // reset list
    list-style: none;
    padding: 0 0 14px;
    margin: 6px 0 0;

    > li {
        &:hover .departments__menu,
        &:hover .departments__megamenu {
            visibility: visible;
        }
        &:hover > a {
            background: $departments-item-hover-bg;
        }
    }
    > li > a {
        font-weight: $departments-font-weight;
        position: relative;
        display: block;
        padding: 10px 16px;
        color: inherit;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: .02em;
    }
}
.departments__item--menu {
    position: relative;
}
.departments__link-arrow {
    top: calc(50% - 5px);
    position: absolute;
    fill: $departments-arrow-color;

    @include direction {
        #{$inset-inline-end}: 14px;
        transform: scaleX(1 * $transform-direction);
    }
}
.departments__megamenu {
    position: absolute;
    top: 0;
    height: 100%;
    visibility: hidden;

    @include direction {
        #{$inset-inline-start}: 100%;
    }
}
.departments__menu {
    position: absolute;
    top: 0;
    height: 100%;
    visibility: hidden;

    @include direction {
        #{$inset-inline-start}: 100%;
    }
}

.departments__megamenu--sm {
    width: (206px * 1 + 31px);
}
.departments__megamenu--nl {
    width: (206px * 2 + 31px);
}
.departments__megamenu--lg {
    width: (206px * 3 + 31px);
}
.departments__megamenu--xl {
    width: (206px * 4 + 31px);
}

.departments__links-wrapper {
    overflow: hidden;
    height: 0;
    opacity: 0;

    transition: height $local-transition-duration ease-in-out, opacity $local-transition-duration ease-in-out;
}

.departments:not(.departments--fixed) {
    .departments__button {
        cursor: pointer;

        &:hover {
            .departments__button-icon,
            .departments__button-arrow {
                fill: currentColor;
            }
        }
    }
}
.departments--fixed {
    .departments__button {

    }
}
.departments--opened {
    .departments__links-wrapper {
        overflow: visible;
        height: auto;
        opacity: 1;
    }
    .departments__button-arrow {
        transform: rotateZ(180deg);
    }
}
.departments--transition {
    .departments__links-wrapper {
        overflow: hidden;
    }
}




@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .departments {
        width: 210px;
    }
    .departments__megamenu--sm {
        width: (172px * 1 + 32px);
    }
    .departments__megamenu--nl {
        width: (172px * 2 + 32px);
    }
    .departments__megamenu--lg {
        width: (172px * 3 + 32px);
    }
    .departments__megamenu--xl {
        width: (172px * 4 + 32px);
    }
}
