.category-dropdown-wrapper {
    max-width: 400px;
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    border: 0.5px solid #AAAAAA;
    //border-bottom: none;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.10);

    top: 93px;

    @media (max-width: 768px) {
        width: 273px;
        right: -35px;
        top: 69px;
    }
}

.mobile_lang_select {
    .topbar__item {
        .topbar-dropdown {
            width: 100%;

            .topbar-dropdown__btn {
                width: 100%;
                padding: 12px 24px;

                .lang_image {
                    width: 26px;
                    height: 18px;
                }

                svg {
                    right: 24px;
                    fill: transparent;
                }
            }

            .menu--layout--topbar {
                width: 271px;

                button {
                    padding-top: 12px;
                    padding-bottom: 12px;

                    .menu_icon {
                        top: 12px;

                        img {
                            height: 18px;

                        }
                    }
                }
            }
        }

    }
}
