/*
// .topbar
*/
@import '../variables';
@import '../mixins/direction';


$local-item-margin: 0;


.topbar {
    height: 40px;
    background: #F5F5F5;
    font-size: 14px;
    line-height: 14px;
    color: #636363;
    position: relative;
    z-index: 20;
}

.topbar__container {
    height: 100%;
    padding: 0 42px;
}

.topbar__row {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 #{-$local-item-margin};
}

.topbar__item {
    margin: 0 $local-item-margin;
    height: 100%;
    display: flex;
    align-items: center;
}

.topbar__item p {
    margin: 0;
    font-size: 18px;
    
}

.topbar__item-value {
    transition: all .1s;
    color: #9F9F9F;
}

.lang_image {
    margin-right: 8px;
}

.topbar__spring {
    flex-grow: 1;
}

.topbar__item--link+.topbar__item--link {
    @include direction {
        #{$margin-inline-start}: 16px;
    }
}