/*
// .products-view
*/
@import '../variables';
@import '../mixins/card';


.products-view {
    position: relative;
}

.products-view__options {
    padding-bottom: 20px;
}

.products-view__pagination {
    padding-top: 7px;
}

.products-view__empty {
    @include card;
    border: none;
    padding: 84px 0;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products-view__empty-title {
    font-size: 24px;
    font-weight: $font-weight-medium;
}

.products-view__empty-subtitle {
    margin-bottom: 24px;
}

.products-view__loader {
    position: absolute;
    left: -10px;
    top: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    z-index: 4;
    background: rgba(#fff, .7);
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
}

.products-view--loading {
    .products-view__loader {
        opacity: 1;
        pointer-events: auto;
    }
}

.loading {
    //width: 100%;
    //text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    span {
        width: 12px;
        height: 12px;
        background: #43b02a;
        border-radius: 50%;
        animation: bounce .5s infinite alternate;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
}

.none_content{
    .loading{
        height: 50vh;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

.loader_wrapper {
    width: 100%;
    height: 100%;
    background: #FFF;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .7;
    left: 0;
    top: 0;

    .loading {
        position: fixed;
        left: calc(50vw - 26px);
        top: 50vh;
    }
}
