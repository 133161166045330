.home_img_fm {
    margin-top: -86px;
    position: relative;
    height: 365px;

    background-image: url("../svg/backgroundHome.png");

    background-repeat: no-repeat;
    background-position: center;
}

.box_shadow_fm {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #212121;
    opacity: 0.2;
}

.search_logo_fm {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.search_logo_fm .logo_fm {
    text-align: center;
    margin-bottom: 34px;
}

.search_logo_fm .search_inp_fm {
    text-align: center;
    margin-bottom: 33px;
}
