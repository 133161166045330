.bottomNavigation-wrapper {
  width: 100%;
  height: 64px;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
  border-top: 1px solid #ebebeb;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.10);
  overflow: hidden;


  .indicatpr_area {
    height: unset;
  }
}

.bottom-nav-item--title {
  margin-top: 5px;
  font-size: 10.3px;
  margin-bottom: 0;
}

.bottom-nav-item--title-active {
  // color: $footer-icon-fill-stroke-color;
  color: red;
}

//   .local-item {
//     path {
//       fill: #AAAAAA !important;
//     }
//   }

.local-item-active {
  background: transparent !important;
  border-bottom: 1px solid #379237;

  path {
    stroke: #43B02A;
  }
}

.authenticated {
  path {
    fill: #43B02A !important;
    stroke: #43B02A !important;
  }
}

.local-item, .local-item.active {
  span {
    height: unset
  }
}


.bottom-nav-item {
  width: 20% !important;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  // transition: all 0.3s ease;
  .indicator__value {
    background: #379237;
    color: #ffffff;
    left: -40px;
  }
}


.bottom-nav {
  .indicator__value {
    margin-left: -8px;
  }


  p {
    left: 0;
    top: 26px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #757575;
  }

  .indicator {
    a {
      height: unset;
    }
  }

  // transition: 1s;
  background: #ffffff !important;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1) !important;

  .bottom-nav-item.active {
    background-color: red !important;

    p {
      // color: $footer-icon-fill-stroke-color !important;
      color: grey;
    }

    .indicator__value {
      // color: $footer-icon-fill-stroke-color;
      color: blue;
    }

    path {
      // fill: $footer-icon-fill-stroke-color;
      color: red;
    }
  }

  .bottom-nav-item.false {
    path {
      fill: #757575;
    }
  }

  .indicator__area {
    position: relative;
    background: 0 0;
    -webkit-transition: background-color 0.2s;
    //   transition: background-color 0.2s;
    border-radius: 2px;
    display: flex;
    align-items: center;
    fill: #fff;
    justify-content: center;
    height: unset !important;
  }
}

.mob_area {
  padding: 0 !important;
}

.click-disabled {
  pointer-events: none;
}
